import CircleIcon from '@mui/icons-material/Circle';

import { Link } from 'react-router-dom';
import { Colors } from 'shared/constants';
import { LogDto } from 'shared/interfaces';
import styled from 'styled-components';

const circleIconStyles = {
  position: 'relative',
  bottom: '1px',
  fontSize: 14,
  paddingRight: 1,
};
type LogItemProps = {
  log: LogDto | undefined;
  systemUid: string;
  isErrorLogs: boolean;
  lastLogTimestamp: number;
};
const LogItem = ({
  log,
  systemUid,
  isErrorLogs = false,
  lastLogTimestamp,
}: LogItemProps) => {
  if (!log) {
    return null;
  }
  const { timestamp, message, module, level } = log;
  const timestampLabel = timestamp;
  const logUrl = `/system/${systemUid}/logs`;
  return (
    <LogInfo isErrorLogs={isErrorLogs}>
      {isErrorLogs && (
        <CircleIcon
          sx={{
            ...circleIconStyles,
            color: level === 3 ? Colors.orange4 : Colors.dark1,
          }}
        />
      )}
      <div>
        <div>{timestampLabel.toLocaleString()}</div>
        <SpacingDiv>
          <BoldText>
            <StyledLink
              to={logUrl}
              state={{
                lastLog: lastLogTimestamp,
                logTimestamp: timestamp.getTime(),
              }}
            >
              {message}
            </StyledLink>
          </BoldText>
        </SpacingDiv>
        <div>{module}</div>
      </div>
    </LogInfo>
  );
};

interface ILogInfo {
  isErrorLogs?: boolean;
}
export const LogInfo = styled.div<ILogInfo>`
  padding-top: 10px;
  padding-bottom: 5px;
  display: ${({ isErrorLogs }) => (isErrorLogs ? 'flex' : undefined)};
  align-items: ${({ isErrorLogs }) => (isErrorLogs ? 'center' : undefined)};
`;
const BoldText = styled.div`
  font-weight: 500;
  color: black;
`;
export const SpacingDiv = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const StyledLink = styled(Link)`
  color: inherit;
  &:visited {
    color: inherit;
  }
`;

export default LogItem;
