import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  millisecondsToMinutes,
  minutesToMilliseconds,
  secondsToMilliseconds,
} from 'date-fns';

import { useAuth } from './AuthProvider';

export interface IDynamoDBClientContext {
  dynamoDBClient: Nullable<DynamoDBClient>;
}
// Create a new context
const DynamoDBContext = createContext<IDynamoDBClientContext>(undefined!);

// Custom hook to access the DynamoDB context
export const useDynamoDB = () => useContext(DynamoDBContext);

// DynamoDB Provider component
export const DynamoDBProvider = ({
  children,
  region,
}: {
  children: ReactNode;
  region: string;
}) => {
  const { authStatus } = useAuth();
  const [dynamoDBClient, setDynamoDBClient] =
    useState<Nullable<DynamoDBClient>>(null);
  const refreshInterval = minutesToMilliseconds(10);

  /**
   * Refreshes the user credentials if they are expired.
   *
   * @returns {Promise<void>} - A promise that resolves when the credentials are refreshed.
   */
  async function refreshDynamoDBClient() {
    const currentSession = await fetchAuthSession();
    const expireEpoch = currentSession.tokens?.accessToken.payload.exp;
    if (!expireEpoch) {
      return;
    }
    const validUntil = new Date(secondsToMilliseconds(expireEpoch));
    const hasAccessTokenExpired = Date.now() > validUntil.getTime();
    console.log(
      `[DynamoDBClientProvider:refreshCredentials] : Refreshing credentials... ${new Date().toLocaleTimeString()}`
    );
    console.log(
      `[DynamoDBClientProvider:refreshCredentials] : ${JSON.stringify(
        {
          validUntil: validUntil.toLocaleTimeString(),
          hasAccessTokenExpired,
          diffMinutes: millisecondsToMinutes(validUntil.getTime() - Date.now()),
        },
        null,
        2
      )}`
    );
    const { credentials } = await fetchAuthSession({ forceRefresh: true });
    setDynamoDBClient(
      new DynamoDBClient({
        region,
        credentials,
      })
    );
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    /**
     * Configures the service based on the current user information.
     *
     * @returns {Promise<void>} - A promise that resolves when the service has been configured.
     */
    async function configureService() {
      const currentUserInfo = await getCurrentUser();
      console.log(
        `[DynamoDBClientProvider:configureService] : ${JSON.stringify(
          { currentUseInfo: currentUserInfo },
          null,
          2
        )}`
      );
      if (!currentUserInfo) {
        setDynamoDBClient(null);
        return;
      }
      const { credentials } = await fetchAuthSession();
      setDynamoDBClient(
        new DynamoDBClient({
          region,
          credentials,
        })
      );
      intervalId = setInterval(refreshDynamoDBClient, refreshInterval);
    }

    configureService();
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  return (
    <DynamoDBContext.Provider value={{ dynamoDBClient }}>
      {children}
    </DynamoDBContext.Provider>
  );
};
