import { getEnumValues, parseEnumValue } from '../utils/enum';

/**
 * Get a string value from the config object
 */
export function getString(
  configValues: Record<string, string>,
  varName: string
): string {
  const value = configValues[varName];

  if (!value) {
    throw new Error(`Missing config value ${varName}`);
  }

  return value;
}

/**
 * Get a boolean value from the config object
 */
export function getBoolean(
  configValues: Record<string, string>,
  varName: string
): boolean {
  const value = getString(configValues, varName);

  return convertToBoolean(varName, value);
}

/**
 * Get an optional string value from the config object
 */
export function getOptionalString(
  configValues: Record<string, string>,
  varName: string
): string | undefined {
  return configValues[varName];
}

/**
 * Get a URL value from the config object
 */
export function getUrl(
  configValues: Record<string, string>,
  varName: string
): URL {
  const value = configValues[varName];

  if (!value) {
    throw new Error(`Missing config value ${varName}`);
  }

  try {
    return new URL(value);
  } catch (_e) {
    throw new Error(
      `Config value ${varName} should be a valid URL, but was not: ${value}`
    );
  }
}

/**
 * Get an Enum value from the config object
 */
export function getEnumValue<T extends string, TEnumValue extends string>(
  configValues: Record<string, string>,
  varName: string,
  enumVariable: { [key in T]: TEnumValue }
): TEnumValue {
  const value = getString(configValues, varName);

  try {
    return parseEnumValue(enumVariable, value);
  } catch (_e) {
    const allowedValues = getEnumValues(enumVariable);
    throw new Error(
      `Config value ${varName} should be one of [${allowedValues.join(',')}], but was ${value}`
    );
  }
}

/**
 * Converts a string to a boolean
 */
function convertToBoolean(varName: string, value: string): boolean {
  if (
    value.trim().toUpperCase() !== 'TRUE' &&
    value.trim().toUpperCase() !== 'FALSE'
  ) {
    throw new Error(
      `Config value ${varName} must be a boolean (true, false), but was ${value}`
    );
  }
  return value.toUpperCase() === 'TRUE';
}
