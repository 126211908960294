import {
  AttributeValue,
  DynamoDBClient,
  QueryCommand,
  QueryInput,
} from '@aws-sdk/client-dynamodb';
import { unmarshall } from '@aws-sdk/util-dynamodb';
import isNil from 'lodash.isnil';

interface ILoopResponse<T> {
  hasMoreData: boolean;
  data: T[];
  lastEvaluatedKey: Optional<Record<string, AttributeValue>>;
}

/**
 * Executes a query in a loop until all data is retrieved from DynamoDB.
 *
 * @param {QueryInput} queryInput - The input parameters for the query.
 * @param {DynamoDBClient} dynamoDBClient - The DynamoDB client to execute the query.
 * @returns {Promise<T[]>} A promise that resolves to an array of log data.
 */
export async function loopQuery<T>(
  queryInput: QueryInput,
  dynamoDBClient: DynamoDBClient
): Promise<T[]> {
  const logDataList: T[] = [];
  let keepGoing = true;
  while (keepGoing) {
    const response: ILoopResponse<T> = await dynamoDBClient
      .send(new QueryCommand(queryInput))
      .then(({ LastEvaluatedKey, Items }) => {
        const data: T[] = Items?.map((item) => unmarshall(item) as T) ?? [];
        const hasMoreData = !isNil(LastEvaluatedKey);
        return {
          hasMoreData,
          lastEvaluatedKey: LastEvaluatedKey,
          data,
        };
      });
    queryInput.ExclusiveStartKey = response.lastEvaluatedKey;
    logDataList.push(...response.data);
    keepGoing = response.hasMoreData;
    if (!isNil(queryInput.Limit)) {
      const getMoreData = logDataList.length < queryInput.Limit;
      keepGoing = response.hasMoreData && getMoreData;
    }
  }
  return logDataList;
}
