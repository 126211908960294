import {
  AppConfig,
  AwsCognitoConfig,
  AwsConfig,
  AwsDynamoDbConfig,
  AwsRegion,
  Environment,
  HasuraConfig,
  ReleaseConfig,
} from './AppConfig';
import {
  getEnumValue,
  getOptionalString,
  getString,
  getUrl,
} from './config-utils';

/**
 * Create an AppConfig object from an object of config values
 */
export function createAppConfig(values: Record<string, string>): AppConfig {
  const appConfig: AppConfig = {
    aws: createAwsConfig(values),
    dashboardBaseUrl: getString(values, 'DASHBOARD_BASE_URL'),
    environment: getEnumValue(values, 'ENV', Environment),
    hasura: createHasuraConfig(values),
    release: createReleaseConfig(values, 'fleet-management'),
  };

  return appConfig;
}

function createAwsConfig(values: Record<string, string>): AwsConfig {
  const awsConfig: AwsConfig = {
    cognito: createAwsCognitoConfig(values),
    dynamoDb: createAwsDynamoDbConfig(values),
  };

  return awsConfig;
}

function createAwsCognitoConfig(
  values: Record<string, string>
): AwsCognitoConfig {
  const awsCognitoConfig: AwsCognitoConfig = {
    identityPoolId: getString(values, 'AWS_COGNITO_IDENTITY_POOL_ID'),
    userPoolId: getString(values, 'AWS_COGNITO_USER_POOL_ID'),
    webClientId: getString(values, 'AWS_COGNITO_WEB_CLIENT_ID'),
  };
  return awsCognitoConfig;
}

function createAwsDynamoDbConfig(
  values: Record<string, string>
): AwsDynamoDbConfig {
  const awsDynamoDbConfig: AwsDynamoDbConfig = {
    region: getEnumValue(values, 'AWS_DYNAMODB_REGION', AwsRegion),
    logsTableName: getString(values, 'AWS_DYNAMODB_LOGS_TABLE_NAME'),
  };
  return awsDynamoDbConfig;
}

function createHasuraConfig(values: Record<string, string>): HasuraConfig {
  const hasuraConfig: HasuraConfig = {
    graphqlEndpoint: getUrl(values, 'HASURA_GRAPHQL_ENDPOINT'),
  };

  return hasuraConfig;
}

function createReleaseConfig(
  configValues: Record<string, string>,
  applicationName: string
): ReleaseConfig {
  const branchName = getOptionalString(configValues, 'COMMIT_BRANCH');
  const tagName = getOptionalString(configValues, 'COMMIT_TAG');
  const commitShortSha = getString(configValues, 'COMMIT_SHORT_SHA');
  const commitSha = getString(configValues, 'COMMIT_SHA');
  const tagVersion = tagName && getTagVersion(tagName);
  return {
    branchName,
    commitSha,
    commitShortSha,
    releaseName: getRelease(
      applicationName,
      commitShortSha,
      branchName,
      tagName
    ),
    tagName,
    tagVersion,
  };
}

function getTagVersion(tagName: string): string {
  const match = tagName.match(/[0-9]+\.[0-9]+\.[0-9]+/);
  if (!match?.length) {
    throw new Error(`Could not get tag version from tag name ${tagName}`);
  }
  return match[0];
}

function getRelease(
  applicationName: string,
  commitHash: string,
  branchName: string | undefined,
  tagName: string | undefined
): string {
  if (tagName) {
    return `${applicationName}@${tagName}`;
  }

  // We can use max. 200 characters for a release in sentry
  // and no forward slashes are allowed
  // https://docs.sentry.io/cli/releases/
  const sanitizedBranchName = (branchName || '').replace('/', '__');
  const refName = sanitizedBranchName.substring(
    0,
    200 - (applicationName.length + commitHash.length + 2)
  );

  return `${applicationName}@${refName}-${commitHash}`;
}
