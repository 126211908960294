import { Breadcrumbs } from '@mui/material';
import { Typography } from './Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useScreenSize } from 'hooks';
import { Colors } from 'shared/constants';
import React from 'react';

interface IPageBreadcrunbsProps {
  systemUid: string;
  page: string;
  pageHref?: string;
  page2?: string;
  fontSize?: number;
  fontWeight?: string;
}
export const PageBreadcrumbs = ({
  systemUid,
  page,
  pageHref,
  page2,
  fontSize,
  fontWeight,
}: IPageBreadcrunbsProps) => {
  const { isMobile } = useScreenSize();
  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon
          sx={{
            fontSize: isMobile ? 14 : 20,
          }}
        />
      }
      aria-label="breadcrumb"
    >
      <StyledLink to="/" fontSize={fontSize} fontWeight={fontWeight}>
        Systems
      </StyledLink>

      {page2 && pageHref ? (
        <StyledLink to={pageHref} fontSize={fontSize} fontWeight={fontWeight}>
          System {systemUid} {page}
        </StyledLink>
      ) : (
        <CurrentPageTypography fontSize={fontSize || 16}>
          System {systemUid} {page}
        </CurrentPageTypography>
      )}

      {page2 && (
        <CurrentPageTypography fontSize={fontSize || 16}>
          System {systemUid} {page2}
        </CurrentPageTypography>
      )}
    </Breadcrumbs>
  );
};

interface IStyledLink {
  fontSize?: number;
  fontWeight?: string;
}

interface ICurrentPageTypography {
  fontSize?: number;
  children: React.ReactNode;
}

const StyledLink = styled(Link)<IStyledLink>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : undefined)}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : undefined)};
  font-family: Poppins;
  color: inherit;
  text-decoration: none;
  &:visited {
    color: inherit;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const CurrentPageTypography = (props: ICurrentPageTypography) => (
  <Typography
    variant="body4"
    fontSize={props.fontSize ? props.fontSize : 16}
    fontWeight="400"
    color={Colors.black}
  >
    {props.children}
  </Typography>
);
