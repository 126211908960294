import {
  GetFieldInterventionQuery,
  GetSystemsQuery,
  useGetFieldInterventionQuery,
} from 'graphql/generated/react_apollo';

import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { SystemDto } from 'shared/interfaces';
import { useGetSystemsByUid } from './system';

export type SystemEntity = ArrayElement<GetSystemsQuery['system']>;
export type GetFieldNotesProps = {
  isFetchAllSystems?: boolean;
  startDate: Date;
  endDate: Date;
};
/**
 * Returns an object containing the result of the useGetSystemsQuery hook, an array of systems,
 * a mapping of system UIDs to systems, and a boolean indicating whether there is system data.
 * The systems array contains all systems if isFetchAllSystems is true, otherwise it contains
 * only active systems.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.isFetchAllSystems - Whether to fetch all systems or only active systems.
 * @param {Date} props.startDate - The start date for the query.
 * @param {Date} props.endDate - The end date for the query.
 * @returns {Object} An object containing the result of the useGetSystemsQuery hook, an array of systems,
 * a mapping of system UIDs to systems, and a boolean indicating whether there is system data.
 * The return object contains the following properties:
 *  - loading: A boolean indicating whether the query is still loading.
 *  - error: An error object if there was an error, otherwise null.
 *  - fieldNotes: An array of FieldNoteDto objects containing the field notes.
 */
export function useGetFieldNotes({
  startDate,
  endDate,
  isFetchAllSystems,
}: GetFieldNotesProps) {
  const [fieldNoteDtos, setFieldNoteDtos] = useState<FieldNoteDto[]>([]);

  const {
    data: fieldNotesData,
    loading,
    error,
  } = useGetFieldInterventionQuery({
    variables: {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    },
  });
  const systemUids = Array.from(
    fieldNotesData?.field_intervention.map((fieldNote) => {
      return fieldNote.system.uid;
    }) ?? []
  );

  const {
    systemUidToSystem,
    loading: systemUidToSystemLoading,
    error: systemUidToSystemError,
  } = useGetSystemsByUid(Array.from(new Set(systemUids)));

  useEffect(() => {
    if (
      !fieldNotesData ||
      fieldNotesData.field_intervention.length === 0 ||
      !systemUidToSystem ||
      systemUidToSystem.size === 0
    ) {
      return;
    }
    const fieildNotesDtos = fieldNotesData.field_intervention
      .filter(
        (fieldNote) => systemUidToSystem.get(fieldNote.system.uid) !== undefined
      )
      .filter((fieldNote) => {
        if (isFetchAllSystems) {
          return true;
        }
        return fieldNote.system.is_active;
      })
      .map((fieldNote) =>
        convertEntityToDto(
          fieldNote,
          systemUidToSystem.get(fieldNote.system.uid) as SystemDto
        )
      );
    setFieldNoteDtos(fieildNotesDtos);
  }, [fieldNotesData, systemUidToSystem, isFetchAllSystems]);

  return {
    loading: loading || systemUidToSystemLoading,
    error: error || systemUidToSystemError,
    data: fieldNoteDtos,
  };
}
export type FieldNoteEntity = ArrayElement<
  GetFieldInterventionQuery['field_intervention']
>;
export type NoteDto = {
  title: string;
  category: string;
  logs: string;
  description: string;
  softwareVersion?: string;
  hardwareVersion?: string;
};
export type FieldNoteDto = {
  id: number;
  system: SystemDto;
  userEmail: string;
  notes: NoteDto;
  createdAt: Date;
  updatedAt: Date;
  category: string;
};
/**
 * Converts a FieldNoteEntity object to an FieldNoteDto object.
 *
 * @param {FieldNoteEntity} entity - The FieldNoteEntity object to convert.
 * @param {SystemDto} system - The system object.
 * @returns {FieldNoteDto} The converted FieldNoteDto object.
 */
function convertEntityToDto(
  entity: FieldNoteEntity,
  system: SystemDto
): FieldNoteDto {
  return {
    id: entity.id,
    system,
    userEmail: entity.user.email,
    notes: {
      title: entity.notes.title,
      category: entity.notes.category,
      logs: entity.notes.logs,
      description: entity.notes.description,
      softwareVersion: entity.notes.softwareVersion,
      hardwareVersion: entity.notes.hardwareVersion,
    },
    createdAt: parseISO(entity.created_at),
    updatedAt: parseISO(entity.updated_at),
    category: entity.category ?? 'n/a',
  };
}
