import { Dispatch, SetStateAction } from 'react';
// import { IOptions } from '../../shared/interfaces';
export interface IOptions {
  value: string;
  label: string;
}
/**
 * Returns an event handler function that sets the selected state using the provided dispatcher.
 *
 * @param {'Dispatch<SetStateAction<IOptions[]>>'} setSelected - The dispatcher to set the selected state.
 * @returns {'(selected: unknown) => void'} The event handler function.
 */
export const createSetSelectedFn = (
  setSelected: (selectedSystems: IOptions[]) => void
) => {
  return (selected: unknown) => {
    setSelected(selected as IOptions[]);
  };
}; /**
 * Returns an event handler function that sets the selected state using the provided dispatcher.
 *
 * @param {'Dispatch<SetStateAction<IOptions[]>>'} setSelected - The dispatcher to set the selected state.
 * @returns {'(selected: unknown) = void'} The event handler function.
 */
export const getEventHandler = (
  setSelected: Dispatch<SetStateAction<IOptions[]>>
) => {
  return (selected: unknown) => {
    setSelected(selected as IOptions[]);
  };
};
