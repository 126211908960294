import { formatDateInFullDayTime } from 'api/system_logs';
import { Filter, Typography } from 'components/common';
import { StyledLink } from 'components/system_details/LogItem';
import { useAppConfig } from 'contexts/AppConfigProvider';
import { formatDistanceToNow } from 'date-fns';
import { useSortableData } from 'hooks';
import { useCallback } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { ESystemColumn } from 'shared/interfaces';
import { getDashboardUrl } from 'shared/utils/systems';
import { PlantHeightDtoWithSystemDto } from './PlantHeightReport';

interface Props {
  data: PlantHeightDtoWithSystemDto[];
  startDate: Date;
  endDate: Date;
}

/**
 * Renders a table of plant height data with system information.
 *
 * @param {Props} props - The component props.
 * @param {Date} props.startDate - The start date for filtering plant height data.
 * @param {Date} props.endDate - The end date for filtering plant height data.
 * @returns {JSX.Element} The rendered plant height table.
 */
function PlantHeightTable(props: Props) {
  const { appConfig } = useAppConfig();
  const { data, startDate, endDate } = props;

  const {
    items: sortedData,
    requestSort,
    sortConfig,
  } = useSortableData<PlantHeightDtoWithSystemDto>(data);
  const getSortDirection = useCallback(
    (name: string) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    },
    [sortConfig]
  );
  const columns: TableColumn<PlantHeightDtoWithSystemDto>[] = [
    {
      name: (
        <Filter
          columnTitle="System"
          onSort={() =>
            requestSort(ESystemColumn.SystemUid, (item) => item.systemUid)
          }
          direction={getSortDirection(ESystemColumn.SystemUid)}
        ></Filter>
      ),
      cell: (row) => {
        const url = `/system/${row.systemUid}`;
        return <StyledLink to={url}>{row.systemUid}</StyledLink>;
      },
    },
    {
      name: (
        <Filter
          columnTitle="Customer"
          onSort={() =>
            requestSort(
              ESystemColumn.Customer,
              (fieldNote) => fieldNote.customerName
            )
          }
          direction={getSortDirection(ESystemColumn.Customer)}
        ></Filter>
      ),
      selector: (row) => row.customerName,
    },
    {
      name: (
        <Filter
          columnTitle="Zone"
          onSort={() =>
            requestSort(ESystemColumn.Zone, (item) => item.zoneName)
          }
          direction={getSortDirection(ESystemColumn.Zone)}
        ></Filter>
      ),
      cell: (row) => {
        const dashboardUrl = getDashboardUrl(
          appConfig.dashboardBaseUrl,
          row.customerCode,
          row.zoneId
        );
        return (
          <a href={dashboardUrl} target="_blank" rel="noopener noreferrer">
            {row.zoneName}
          </a>
        );
      },
    },
    {
      name: (
        <Filter
          columnTitle="Latest min height"
          onSort={() =>
            requestSort(
              ESystemColumn.SpysderCanopyDistance,
              (item) => item.mins.at(-1)!
            )
          }
          direction={getSortDirection(ESystemColumn.SpysderCanopyDistance)}
        ></Filter>
      ),
      selector: (row) => row.mins.at(-1)!,
    },
    {
      name: (
        <Filter
          columnTitle="Timestamp"
          onSort={() =>
            requestSort(ESystemColumn.SpysderCanopyDistanceTimestamp, (item) =>
              item.timestamps.at(-1)!.getTime()
            )
          }
          direction={getSortDirection(
            ESystemColumn.SpysderCanopyDistanceTimestamp
          )}
        />
      ),
      selector: (row) =>
        formatDateInFullDayTime(row.timestamps.at(-1)!) +
        ' | ' +
        formatDistanceToNow(row.timestamps.at(-1)!),
    },
  ];
  let table = <></>;
  if (sortedData.length > 0 && sortedData.length === 0) {
    table = (
      <Typography variant="body2">
        Systems available but all are filtered out. Try adjusting filters.
        <span>
          {formatDateInFullDayTime(startDate)} -{' '}
          {formatDateInFullDayTime(endDate)}
        </span>
      </Typography>
    );
  } else {
    table = (
      <DataTable
        columns={columns}
        data={sortedData}
        persistTableHead={true}
        striped
        dense
      />
    );
  }
  return table as JSX.Element;
}

export default PlantHeightTable;
