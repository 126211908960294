import React from 'react';
import styled from 'styled-components';
import ClearIcon from 'assets/svg/clear-button.svg?react';
import searchIcon from '../../assets/svg/search.svg';

export const SearchBox = ({
  id,
  searchText,
  setSearchText,
}: {
  id: string;
  searchText?: string;
  setSearchText?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <>
      <TextField
        data-testid="search"
        id={id}
        type="text"
        value={searchText}
        onChange={(e) => {
          if (setSearchText) setSearchText(e.target.value);
        }}
      />
      {searchText && (
        <ClearButton
          data-testid="clear"
          type="button"
          onClick={() => {
            if (setSearchText) setSearchText('');
          }}
        >
          <ClearIcon />
        </ClearButton>
      )}
    </>
  );
};
const TextField = styled.input`
  height: 32px;
  width: 100%;
  border: 1px solid #e5e5e5;
  padding: 0 10px;
  margin-left: 10px;
  border-radius: 15px;
  background-image: ${(props) => (props.value ? 'none' : `url(${searchIcon})`)};
  background-repeat: no-repeat;
  background-position: left;
  background-origin: content-box;
  &:focus {
    background-image: none;
  }
`;
const ClearButton = styled.button`
  height: 20px;
  width: 20px;
  background-color: white;
  margin-left: -30px;
  padding-top: 0px;
  border-style: none;
`;
