export const Fonts = {
  h1: { size: 48, style: 'normal', weight: 600 },
  h2: { size: 40, style: 'normal', weight: 600 },
  h3: { size: 36, style: 'normal', weight: 600 },
  h4: { size: 26, style: 'normal', weight: 600 },
  h5: { size: 20, style: 'normal', weight: 600 },
  h6: { size: 18, style: 'normal', weight: 600 },
  body1: { size: 16, style: 'normal', weight: 'normal' },
  body2: { size: 14, style: 'normal', weight: 'normal' },
  body3: { size: 18, style: 'normal', weight: 400 },
  body4: { size: 14, style: 'normal', weight: 300 },
};
