export const APP_URL = {
  home: '/',
  reports: '/reports',
  plantHeight: '/plant_height',
  systems: '/systems',
  login: '/login',
  forgot: '/forgot',
  reset: '/reset',
  notFound: '/not-found',
  resetPassword: (username: string) => `/reset?username=${username}`,
};
