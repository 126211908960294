import Select, { CSSObjectWithLabel } from 'react-select';
import { Colors } from 'shared/constants';
import styled from 'styled-components';
import { IOptions } from '../../shared/interfaces';

interface ISelectBoxProps {
  options: string[];
  handleSelect: (newValue: unknown) => void;
  isMobile?: boolean;
  columnTitle: string;
  selectedValues?: IOptions[];
  padding?: string;
  borderRadius?: number;
  borderColor?: string;
  borderColorHovered?: string;
  borderColorFocused?: string;
}

export const SelectBox = ({
  options,
  handleSelect,
  isMobile = false,
  columnTitle,
  selectedValues,
  padding,
  borderRadius,
  borderColor,
  borderColorHovered,
  borderColorFocused,
}: ISelectBoxProps) => {
  const uniqueOptions = Array.from(new Set(options)).map((option, index) => {
    return {
      value: index,
      label: option,
    };
  });

  return (
    <>
      <StyledSelect
        isMobile
        isMulti
        styles={{
          option: (provided) =>
            ({
              ...provided,
              fontSize: isMobile ? '125%' : '100%',
            }) as CSSObjectWithLabel,
          placeholder: (provided) =>
            ({
              ...provided,
              fontSize: isMobile ? '125%' : '100%',
            }) as CSSObjectWithLabel,
          multiValueLabel: (provided) =>
            ({
              ...provided,
              fontSize: isMobile ? '125%' : '100%',
            }) as CSSObjectWithLabel,
          control: (provided, state) =>
            ({
              ...provided,
              borderRadius: isMobile
                ? '1.5rem'
                : `${borderRadius ? borderRadius : '30'}px`,
              padding: padding ? padding : '0rem 0.7rem',
              minHeight: isMobile ? '3rem' : '10px',
              borderColor: borderColor
                ? state.isFocused
                  ? borderColorFocused
                  : borderColor
                : Colors.dark7,
              '&:hover': {
                borderColor: borderColorHovered
                  ? borderColorHovered
                  : Colors.dark7,
              },
              boxShadow: state.isFocused
                ? borderColorFocused
                  ? `0 0 0 1px ${borderColorFocused}`
                  : `0 0 0 1px ${Colors.blue3}`
                : 'none',
            }) as CSSObjectWithLabel,
        }}
        name="select"
        inputId="select"
        options={uniqueOptions}
        onChange={handleSelect}
        value={selectedValues}
        placeholder={isMobile && <div>{columnTitle}</div>}
        components={{
          DropdownIndicator: (isMobile) => !isMobile || null,
          IndicatorSeparator: (isMobile) => !isMobile || null,
        }}
      />
    </>
  );
};
SelectBox.displayName = 'SelectBox';
interface ISelect extends Select {
  isMobile: boolean;
}

const StyledSelect = styled(Select)<ISelect>`
  margin-left: ${({ isMobile }) => (isMobile ? '0px' : '10px')};
  width: ${({ isMobile }) => (isMobile ? '100%' : '80%')};
`;
