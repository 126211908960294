import DownIcon from 'assets/svg/down-arrow.svg?react';
import SortIcon from 'assets/svg/sort-arrows.svg?react';
import UpIcon from 'assets/svg/up-arrow.svg?react';
import React from 'react';
import styled from 'styled-components';
import { IOptions } from '../../shared/interfaces';
import { SearchBox } from './SearchBox';
import { SelectBox } from './SelectBox';
import { SortButton } from './SortButton';

interface IFilterConfig {
  columnTitle: string;
  addSelect?: boolean;
  options?: string[];
  handleSelect?: (newValue: unknown) => void;
  addSearch?: boolean;
  searchText?: string;
  setSearchText?: React.Dispatch<React.SetStateAction<string>>;
  addSort?: boolean;
  onSort?: React.MouseEventHandler<HTMLButtonElement>;
  direction?: string;
  isMobile?: boolean;
  selectedValues?: IOptions[];
  selectBoxPadding?: string;
  SelectBoxBorderRadius?: number;
  SelectBoxBorderColor?: string;
  SelectBoxBorderColorHovered?: string;
  SelectBoxBorderColorFocused?: string;
}

export const Filter = ({
  columnTitle,
  addSelect = true,
  options,
  handleSelect,
  addSearch = false,
  searchText = '',
  setSearchText,
  addSort = true,
  onSort,
  direction,
  isMobile = false,
  selectedValues,
  selectBoxPadding,
  SelectBoxBorderRadius,
  SelectBoxBorderColor,
  SelectBoxBorderColorHovered,
  SelectBoxBorderColorFocused,
}: IFilterConfig) => {
  return (
    <Flexbox>
      {isMobile || <StyledColumnTitle>{columnTitle}</StyledColumnTitle>}
      {addSelect && options && handleSelect && (
        <SelectBox
          options={options}
          handleSelect={handleSelect}
          isMobile={isMobile}
          columnTitle={columnTitle}
          selectedValues={selectedValues}
          padding={selectBoxPadding}
          borderRadius={SelectBoxBorderRadius}
          borderColor={SelectBoxBorderColor}
          borderColorHovered={SelectBoxBorderColorHovered}
          borderColorFocused={SelectBoxBorderColorFocused}
        />
      )}
      {addSearch && (
        <SearchBox
          id={columnTitle}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      )}
      {addSort && (
        <SortButton type="button" onClick={onSort} direction={direction}>
          {direction === 'ascending' && (
            <DownIcon data-testid="ascending-icon" height={20} width={10} />
          )}
          {direction === 'descending' && (
            <UpIcon data-testid="descending-icon" height={20} width={10} />
          )}
          {!direction && (
            <SortIcon data-testid="sort-icon" height={20} width={10} />
          )}
        </SortButton>
      )}
    </Flexbox>
  );
};

const Flexbox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const StyledColumnTitle = styled.div`
  padding-right: 10px;
`;
