import { ProtectedRoute } from 'ProtectedRoute';
import FieldReport from 'components/fleet_reports/FieldReport';
import PlantHeightReport from 'components/plant_height_reports/PlantHeightReport';
import { SystemDetailsProvider } from 'contexts/SystemDetailsProvider';
import { lazy } from 'react';
import { Routes as BrowserRoutes, Route } from 'react-router-dom';
import { APP_URL } from 'shared/constants/url';

const LoginPage = lazy(() => import('pages/auth/LoginPage'));
const ForgotPasswordPage = lazy(() => import('pages/auth/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('pages/auth/ResetPasswordPage'));

const ListSystems = lazy(
  () => import('components/systems_overview/SystemsOverview')
);
const NotFoundPage = lazy(() => import('pages/not_found/NotFoundPage'));
const SystemConfigPage = lazy(
  () => import('components/system_config/SystemConfig')
);
const SystemDetailsPage = lazy(
  () => import('components/system_details/SystemDetails')
);
const LogDetailsPage = lazy(() => import('components/log_details/LogDetails'));

const Routes = () => {
  return (
    <BrowserRoutes>
      <Route path={APP_URL.home} element={<LoginPage />} />
      <Route path={APP_URL.login} element={<LoginPage />} />
      <Route path={APP_URL.forgot} element={<ForgotPasswordPage />} />
      <Route path={APP_URL.reset} element={<ResetPasswordPage />} />
      <Route
        path={APP_URL.systems}
        element={
          <ProtectedRoute>
            <ListSystems />
          </ProtectedRoute>
        }
      />
      <Route
        path="/plant_height"
        element={
          <ProtectedRoute>
            <PlantHeightReport />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reports"
        element={
          <ProtectedRoute>
            <FieldReport />
          </ProtectedRoute>
        }
      />
      <Route
        path="/system/:systemUid/logs"
        element={
          <ProtectedRoute>
            <SystemDetailsProvider>
              <LogDetailsPage />
            </SystemDetailsProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/system/:systemUid"
        element={
          <ProtectedRoute>
            <SystemDetailsProvider>
              <SystemDetailsPage />
            </SystemDetailsProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/system/:systemUid/config"
        element={
          <ProtectedRoute>
            <SystemDetailsProvider>
              <SystemConfigPage />
            </SystemDetailsProvider>
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </BrowserRoutes>
  );
};

export default Routes;
