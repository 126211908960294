import { getCurrentUser, signIn, signOut } from 'aws-amplify/auth';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserByEmailLazyQuery } from '../graphql/generated/react_apollo';
import { APP_URL } from '../shared/constants';
import { EAuthStatus, TUser } from '../shared/interfaces';

interface IBooleanMap {
  [key: string]: boolean;
}
/**
 * AuthContext definition
 */
export interface IAuthContextType {
  /** Shows whether the user is logged in or not. */
  authStatus: EAuthStatus;
  /** The user's email. */
  email: string;
  /** The user's first name. */
  firstName: string;
  /** The user's last name. */
  lastName: string;
  /** `true` if loading the user information, `false` otherwise. */
  loading: boolean;
  /** The user's name initials. */
  nameInitials: string;
  /** The user information from db. */
  user: Maybe<TUser>;
  /** The user id in the db. */
  userId: Maybe<number>;
  /** UserInfo that has the authenticated user information. */

  userInfo: any;
  /** The user's phone number. */
  phoneNumber: string;
  /** Callback to login with email and password. */
  login: (email: string, password: string) => void;
  /** Callback to sign out from the application. */
  logout: () => Promise<void>;
  /** Feature flag for user. */
  featureFlagMap: IBooleanMap;
}

export const AuthContext = createContext<IAuthContextType>(undefined!);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState<any>();
  const [authStatus, setLoggedInStatus] = useState(EAuthStatus.UNDETERMINED);

  const email = useMemo(() => {
    return userInfo?.attributes?.email ?? userInfo?.username;
  }, [userInfo]);

  const [getUserByEmail, { data, loading }] = useGetUserByEmailLazyQuery({
    variables: { email },
  });

  useEffect(() => {
    if (email) {
      getUserByEmail({
        variables: {
          email,
        },
      });
    }
  }, [email, getUserByEmail]);

  const user: Maybe<TUser> = useMemo(() => data?.user?.[0], [data]);
  const userId = user?.id;

  const featureFlagMap: IBooleanMap = useMemo(() => {
    return JSON.parse(userInfo?.attributes?.['custom:metadata'] ?? '{}')[
      'featureFlags'
    ];
  }, [userInfo]);

  const firstName = useMemo(() => {
    return (
      user?.first_name ??
      userInfo?.attributes?.['given_name'] ??
      userInfo?.firstName ??
      userInfo?.attributes?.['name'] ??
      ''
    );
  }, [user, userInfo]);

  const lastName = useMemo(() => {
    return (
      user?.last_name ??
      userInfo?.attributes?.['family_name'] ??
      userInfo?.familyName ??
      ''
    );
  }, [user, userInfo]);

  const phoneNumber = useMemo(() => {
    return user?.phone_number ?? userInfo?.attributes?.phone_number ?? '';
  }, [user, userInfo]);

  const nameInitials = useMemo(() => {
    return [firstName, lastName]
      .map((each) => each?.charAt(0))
      .filter((each) => each)
      .join('');
  }, [firstName, lastName]);

  const authenticateCurrentUser = useCallback(async () => {
    getCurrentUser()
      .then((userInfo) => {
        setUserInfo(userInfo);
        setLoggedInStatus(EAuthStatus.LOGGED_IN);
      })
      .catch(() => {
        setLoggedInStatus(EAuthStatus.LOGGED_OUT);
      });
  }, []);

  const logout = useCallback(async () => {
    await signOut();
    setLoggedInStatus(EAuthStatus.LOGGED_OUT);
    navigate(APP_URL.login);
  }, [navigate]);

  const login = useCallback(async (username: string, password: string) => {
    try {
      const userInfo = await signIn({ username, password });

      setUserInfo(userInfo);
      setLoggedInStatus(EAuthStatus.LOGGED_IN);
    } catch (error) {
      setLoggedInStatus(EAuthStatus.LOGGED_OUT);
      throw error;
    }
  }, []);

  useEffect(() => {
    authenticateCurrentUser();
  }, [authenticateCurrentUser]);

  return (
    <AuthContext.Provider
      value={{
        authStatus,
        featureFlagMap,
        email,
        firstName,
        lastName,
        loading,
        nameInitials,
        phoneNumber,
        user,
        userId,
        userInfo,
        login: login,
        logout: logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
