export const Colors = {
  blue3: '#2684ff',
  black: '#000000',
  dark1: '#383838',
  dark2: '#525252',
  dark7: '#808080',
  dark8: '#B4B4B4',
  dark9: '#CFCFCF',
  darkgreen: '#65a364',
  green1: '#56C254',
  green4: '#f0fff0',
  greenTransparent1: 'rgba(49, 202, 113, 0.17)',
  grey5: '#E0E0E0',
  grey7: '#F7F7F7',
  hb1: '#82879A',
  hb2: '#9BA0B6',
  hb3: '#C3C7D9',
  hb4: '#ECEDF1',
  hb5: '#F0F1F4',
  hb6: '#F8F8FC',
  red1: '#DD382F',
  redTransparent1: 'rgba(221, 56, 47, 0.17)',
  orange2: '#E86339',
  orange3: '#F48E42',
  orange4: '#FFB94C',
  orange7: '#f7e8d0',
  orangeTransparent1: 'rgba(250, 200, 0, 0.17)',
  orangeTransparent2: 'RGBA(234, 127, 21, 0.17)',
  white: '#ffffff',
  linearGradientedOrange: 'linear-gradient(90deg, #E86339 0%, #FFB94C 100%)',
};
