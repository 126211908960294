import { GetUserByEmailQuery } from '../../graphql/generated/react_apollo';

export enum EAuthStatus {
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
  UNDETERMINED = 'UNDETERMINED',
}
type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type TPasswordRule = {
  label: string;
  check: (password: string, confirmPassword: string) => boolean;
};

export type TUser = ArrayElement<GetUserByEmailQuery['user']>;
