import { systemTimeframeMinutes } from 'shared/constants';

export const setStartDateToTimeframe = () => {
  const now = new Date();
  return new Date(now.setMinutes(now.getMinutes() - systemTimeframeMinutes));
};

/**
 * Generates the URL for the dashboard based on the organization code and zone ID.
 *
 * @param {string} organizationCode - The code of the organization.
 * @param {number} zoneId - The ID of the zone.
 * @returns {string} The URL for the dashboard.
 */
export function getDashboardUrl(
  dashboardBaseUrl: string,
  organizationCode: string,
  zoneId: number
) {
  return `${dashboardBaseUrl}/${organizationCode}/zone-details/${zoneId}/homepage-tab`;
}
