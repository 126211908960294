import {
  GetSystemsQuery,
  useGetSystemsByUidsQuery,
  useGetSystemsQuery,
} from 'graphql/generated/react_apollo';
import { useEffect, useMemo, useState } from 'react';

import { parseISO } from 'date-fns';
import { ESystemSpyderStatus, LogDetails, SystemDto } from 'shared/interfaces';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  useGetSystemUidToLastTimestamp,
  useGetSystemLogDetails as useGetSystemUidToLogDetails,
} from './system_logs';

export type SystemEntity = ArrayElement<GetSystemsQuery['system']>;

interface GetSystemsProps {
  isFetchAllSystems: boolean;
  startDate: Date;
  endDate: Date;
}
/**
 * Returns an object containing the result of the useGetSystemsQuery hook, an array of systems,
 * a mapping of system UIDs to systems, and a boolean indicating whether there is system data.
 * The systems array contains all systems if isFetchAllSystems is true, otherwise it contains
 * only active systems.
 *
 * @param {boolean} isFetchAllSystems - Whether to fetch all systems or only active systems.
 * @param {Date} startDate - The start date for the query.
 * @param {Date} endDate - The end date for the query.
 * @returns {Object} An object containing the result of the useGetSystemsQuery hook, an array of systems,
 * a mapping of system UIDs to systems, and a boolean indicating whether there is system data.
 */
export function useGetSystemAndLogData({
  isFetchAllSystems,
  startDate,
  endDate,
}: GetSystemsProps) {
  const { data, loading, error } = useGetSystemsQuery({ pollInterval: 10000 });
  const [systemUidToSystem, setSystemUidToSystem] = useState(
    new Map<string, SystemDto>()
  );
  const allSystemsData: SystemEntity[] = data?.system ?? [];
  const {
    data: systemUidToLastTimestamp,
    loading: systemUidToLastTimestampLoading,
    error: systemUidToLastTimestampError,
  } = useGetSystemUidToLastTimestamp(Array.from(systemUidToSystem?.keys()));

  const { data: systemUidToLogData, loading: systemUidToLogDataLoading } =
    useGetSystemUidToLogDetails(
      Array.from(systemUidToSystem?.keys()),
      startDate,
      endDate
    );
  useDeepCompareEffect(() => {
    if (
      allSystemsData.length === 0 ||
      loading ||
      systemUidToLastTimestampLoading ||
      systemUidToLogDataLoading
    )
      return;
    let tempSystemEntities = [];
    if (isFetchAllSystems) {
      tempSystemEntities = allSystemsData;
    } else {
      tempSystemEntities = allSystemsData?.filter(
        (system: SystemEntity) => system.is_active
      );
    }
    const systemDtos: SystemDto[] = tempSystemEntities.map((system) => {
      return convertEntityToDto({
        systemEntity: system,
        lastLogTimestamp: systemUidToLastTimestamp.get(system.uid),
        logData: systemUidToLogData.get(system.uid),
      });
    });
    const map = systemDtos.reduce((acc, system: SystemDto) => {
      acc.set(system.systemUid, system);
      return acc;
    }, new Map<string, SystemDto>());
    setSystemUidToSystem(map);
  }, [
    allSystemsData,
    isFetchAllSystems,
    systemUidToLastTimestamp,
    systemUidToLogData,
  ]);

  return {
    loading: loading || systemUidToLastTimestampLoading,
    systemsLoading: loading,
    systemsError: error,
    systemUidToLastTimestampLoading,
    systemUidToLastTimestampError,
    systemUidToSystem,
    hasSystemData: allSystemsData.length > 0,
  };
}

type convertEntityToDtoProps = {
  systemEntity: SystemEntity;
  lastLogTimestamp: Optional<Date>;
  logData: Optional<LogDetails>;
};
/**
 * Converts a SystemEntity object to an SytemDto object.
 *
 * @param {SystemEntity} systemEntity - The SystemEntity object to convert.
 * @param {Optional<Date>} lastLogTimestamp - The last log timestamp for the system.
 * @param {Optional<ILogData[]>} logData - The last log timestamp for the system.
 * @returns {SystemDto} The converted SytemDto object.
 */
function convertEntityToDto({
  systemEntity: entity,
  lastLogTimestamp,
  logData,
}: convertEntityToDtoProps): SystemDto {
  return {
    systemId: entity.id,
    systemUid: entity.uid,
    customerId: entity.organization_id,
    customerName: entity.organization.enumeration.description,
    customerCode: entity.organization.enumeration.code,
    lastRun: entity.measurement_runs[0]
      ? parseISO(entity.measurement_runs[0].end_time)
      : undefined,
    lastLog: lastLogTimestamp,
    zoneId: entity.zone_id,
    zoneName: entity.zone.enumeration.description,
    zoneUid: entity.zone.uid,
    systemStatus: entity.system_status as ESystemSpyderStatus,
    systemStatusCreatedAt: parseISO(entity.system_status_created_at),
    systemStatusUpdatedAt: parseISO(entity.system_status_updated_at),
    logDetails: logData,
    metadata: entity.metadata,
    softwareVersion: `${entity.softwareVersion.major}.${entity.softwareVersion.minor}.${entity.softwareVersion.patch}`,
    hardwareVersion: `${entity.hardwareVersion.major}.${entity.hardwareVersion.minor}.${entity.hardwareVersion.patch}`,
  };
}

/**
 * Returns an object containing the result of the useGetSystemsQuery hook, an array of systems,
 * a mapping of system UIDs to systems, and a boolean indicating whether there is system data.
 * The systems array contains all systems if isFetchAllSystems is true, otherwise it contains
 * only active systems.
 *
 * @param {Array<string>} uids - An array of system UIDs to fetch.
 * @returns {Object} An object containing the result of the useGetSystemsQuery hook, an array of systems,
 * a mapping of system UIDs to systems, and a boolean indicating whether there is system data.
 * The return object contains the following properties:
 *  - loading: A boolean indicating whether the query is still loading.
 *  - systemsLoading: A boolean indicating whether the query is still loading.
 *  - systemsError: An error object if there was an error, otherwise null.
 *  - systemUidToSystem: A mapping of system UIDs to systems.
 *  - hasSystemData: A boolean indicating whether there is system data.
 */
export function useGetSystemsByUid(uids: string[]) {
  const { data, loading, error } = useGetSystemsByUidsQuery({
    variables: {
      uids,
    },
    skip: uids.length === 0,
  });
  const [systemUidToSystem, setSystemUidToSystem] = useState(
    new Map<string, SystemDto>()
  );
  const systemsData: SystemEntity[] = data?.system ?? [];

  useEffect(() => {
    if (systemsData.length === 0 || loading) {
      return;
    }
    const systemDtos: SystemDto[] = systemsData.map((system) => {
      return convertEntityToDto({
        systemEntity: system,
        logData: undefined,
        lastLogTimestamp: undefined,
      });
    });
    const map = systemDtos.reduce((acc, system: SystemDto) => {
      acc.set(system.systemUid, system);
      return acc;
    }, new Map<string, SystemDto>());
    setSystemUidToSystem(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemsData]);

  return {
    loading: loading,
    error,
    systemUidToSystem,
    hasSystemData: systemsData.length > 0,
  };
}

/**
 * Returns an object containing the result of the useGetSystemsQuery hook, an array of systems,
 * a mapping of system UIDs to systems, and a boolean indicating whether there is system data.
 * The systems array contains all systems if isFetchAll is true, otherwise it contains
 * only active systems.
 *
 * @param {boolean} isFetchAll - Whether to fetch all systems or only active systems.
 * @returns {Object} An object containing the result of the useGetSystemsQuery hook, an array of systems,
 * a mapping of system UIDs to systems, and a boolean indicating whether there is system data.
 * The return object contains the following properties:
 *  - loading: A boolean indicating whether the query is still loading.
 *  - error: An error object if there was an error, otherwise null.
 *  - systemUidToSystem: A mapping of system UIDs to systems.
 *  - hasSystemData: A boolean indicating whether there is system data.
 */
export function useGetSystems(isFetchAll: boolean) {
  const { data, loading, error } = useGetSystemsQuery();
  const [systemUidToSystem, setSystemUidToSystem] = useState(
    new Map<string, SystemDto>()
  );
  const systemsData: SystemEntity[] = useMemo(
    () => data?.system ?? [],
    [data?.system]
  );

  useEffect(() => {
    if (systemsData.length === 0 || loading) {
      return;
    }
    const systemDtos: SystemDto[] = systemsData
      .filter((system) => isFetchAll || system.is_active)
      .map((system) => {
        return convertEntityToDto({
          systemEntity: system,
          logData: undefined,
          lastLogTimestamp: undefined,
        });
      });

    const map = systemDtos.reduce((acc, system: SystemDto) => {
      acc.set(system.systemUid, system);
      return acc;
    }, new Map<string, SystemDto>());
    setSystemUidToSystem(map);
  }, [systemsData, isFetchAll, loading]);

  return {
    loading: loading,
    error,
    systemUidToSystem,
    hasSystemData: systemsData.length > 0,
  };
}
