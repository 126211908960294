import { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  /**
   * Shows the button in full width mode.
   */
  fullWidth?: boolean;
  /**
   * The type of button.
   *  - By default, it sets to button.
   *  - In the form, it can be submit to be used as a submit button.
   */
  type?: 'submit' | 'reset' | 'button';
  /**
   * Sorted direction which determines arrow shown in button
   */
  direction?: 'ascending' | 'descending' | string;
}
/**
 * Button presentation component.
 */
export const SortButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, direction, fullWidth, ...rest }, ref) => {
    return (
      <StyledButton
        direction={direction}
        fullWidth={fullWidth}
        ref={ref}
        {...rest}
      >
        {children}
      </StyledButton>
    );
  }
);

SortButton.displayName = 'SortButton';

interface StyledButtonProps
  extends Pick<ButtonProps, 'direction' | 'fullWidth'> {}

const StyledButton = styled.button<StyledButtonProps>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 15px 15px;
  font-weight: 500;
  font-size: inherit;
  display: flex;
  align-items: center;
`;
