import { InMemoryCache, makeVar } from '@apollo/client';
import { NoteDto } from 'api/field_notes';
import { IOptions } from 'shared/interfaces';

export const cache: InMemoryCache = new InMemoryCache({});

const headerMessageInitialValue = '';
export const headerMessageVar = makeVar<string>(headerMessageInitialValue);

// Notes dialog
export interface INoteFormInputs extends NoteDto {
  id: Optional<number>;
  author: string;
}
export enum NoteFormType {
  CREATE = 'create',
  UPDATE = 'update',
  READ_ONLY = 'read_only',
}
export const noteDataVar = makeVar<INoteFormInputs>({
  id: undefined,
  title: '',
  description: '',
  logs: '',
  author: '',
  category: '',
  softwareVersion: '',
  hardwareVersion: '',
});
export const noteFormTypeVar = makeVar<NoteFormType>(NoteFormType.CREATE);

export const showAllSystemsVar = makeVar<boolean>(false);

export const selectedSystemsVar = makeVar<IOptions[]>([]);
export const selectedCustomersVar = makeVar<IOptions[]>([]);
export const selectedCategoriesVar = makeVar<IOptions[]>([]);
export const selectedZonesVar = makeVar<IOptions[]>([]);
export const selectedSoftwareVersionsVar = makeVar<IOptions[]>([]);
export const selectedHardwareVersionsVar = makeVar<IOptions[]>([]);
