import ErrorIcon from 'assets/svg/error.svg?react';
import NotificationIcon from 'assets/svg/notification.svg?react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TDialogProps,
  Typography,
} from 'components/common';
import { useScreenSize } from 'hooks';
import { FC } from 'react';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    mobileScrollPaper: {
      alignItems: 'flex-end',
    },
    mobilePaper: {
      margin: 0,
      width: '100vw',
      overflowX: 'hidden',
    },
    dialog: {
      '.MuiDialog-paper': {
        borderRadius: theme.spacing(4),
      },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 4),
      overflow: 'hidden',
      minWidth: 300,
    },
    actions: {
      display: 'flex',
      alignItems: 'stretch',
      padding: theme.spacing(4),
    },
  })
);

export type TConfirmDialogVariant = 'success' | 'error';
interface VoidFunction {
  (): void;
}

interface IConfirmDialogProps extends Omit<TDialogProps, 'title'> {
  /** The button text. */
  buttonText?: string;
  /** The dialog title to be shown in the dialog. */
  title?: string;
  /** The message to be shown in the dialog. */
  message?: string;
  /** The dialog variant among success and error. */
  variant?: TConfirmDialogVariant;
  /** Callback to be called when the dialog closes. */
  onCloseAction: VoidFunction;
  /** Set the open state of the dialog. */
  setOpenDialog: (open: boolean) => void;
}

export const ConfirmDialog: FC<IConfirmDialogProps> = ({
  buttonText = 'Close',
  message,
  title,
  variant = 'success',
  open,
  onCloseAction,
  setOpenDialog,
}) => {
  const { isMobile } = useScreenSize();
  const classes = useStyles();
  const dialogTitle =
    title ?? (variant === 'success' ? 'Success!' : 'Oops, an error occurred!');

  return (
    <Dialog
      aria-label={dialogTitle}
      className={classes.dialog}
      classes={{
        scrollPaper: isMobile ? classes.mobileScrollPaper : undefined,
        paper: isMobile ? classes.mobilePaper : undefined,
      }}
      open={open}
      onClose={onCloseAction}
      data-testid="dialog"
    >
      <DialogContent className={classes.content}>
        {variant === 'success' ? <NotificationIcon /> : <ErrorIcon />}
        <Typography variant="h6">{dialogTitle}</Typography>
        {message ? <Typography variant="body1">{message}</Typography> : null}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color="secondary"
          fullWidth
          onClick={() => setOpenDialog(false)}
        >
          Cancel
        </Button>
        <Button color="primary" fullWidth onClick={onCloseAction}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
