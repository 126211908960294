import isNil from 'lodash.isnil';
import { useState } from 'react';
import { roundToNDigits } from 'shared/utils/utils';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { SystemDto } from 'shared/interfaces';
import { useGetMeasurementRunsMetadataBetweenDatesByZones } from './measurement';
type PlantHeightProps = {
  startDate: Date;
  endDate: Date;
  zoneUidToSystem: Map<string, SystemDto>;
};
export interface PlantHeightDto {
  zoneUid: string;
  zoneName: string;
  customerCode: string;
  mins: number[];
  maxs: number[];
  means: number[];
  timestamps: Date[];
}

/**
 * Custom React hook that fetches plant height data for a given set of zones and time range.
 *
 * @param {Object} props - An object containing the following properties:
 *   @param {Map<string, SystemDto>} props.zoneUidToSystem - A map of zone UIDs to system data.
 *   @param {Date} props.startDate - The start date of the time range.
 *   @param {Date} props.endDate - The end date of the time range.
 * @returns {Object} An object containing the following properties:
 *   @property {Map<string, PlantHeightDto>} zoneUidToPlantHeight - A map of zone UIDs to plant height data.
 *   @property {boolean} loading - A boolean indicating if the data is still loading.
 *   @property {Error} error - An error object if there was an error fetching the data.
 */
function useGetPlantHeight({
  zoneUidToSystem,
  startDate,
  endDate,
}: PlantHeightProps) {
  const [zoneUidToPlantHeight, setZoneUidToMinPlantHeight] = useState<
    Map<string, PlantHeightDto>
  >(new Map());
  const zoneUids = Array.from(zoneUidToSystem.keys());
  const { zoneUidToTimeSeriesData, loading, error } =
    useGetMeasurementRunsMetadataBetweenDatesByZones({
      parameters: {
        zoneUids,
        start: startDate,
        end: endDate,
        typeConfig: {
          metadataPath: 'statistics.calculated_distance',
          convertFromUnit: (value: number) => value,
        },
      },
      skip: isNil(zoneUids) || zoneUids.length === 0,
    });

  useDeepCompareEffect(() => {
    if (!zoneUidToTimeSeriesData || loading) return;
    const zoneUidToPlantHeightTemp = new Map<string, PlantHeightDto>();

    zoneUids.forEach((zoneUid) => {
      let plantHeight = zoneUidToPlantHeightTemp.get(zoneUid);
      if (!plantHeight) {
        plantHeight = {
          zoneUid,
          zoneName: zoneUidToSystem.get(zoneUid)?.zoneName ?? '',
          customerCode: zoneUidToSystem.get(zoneUid)?.customerCode ?? '',
          mins: [],
          maxs: [],
          means: [],
          timestamps: [],
        };
      }
      const timeSeriesData = zoneUidToTimeSeriesData.get(zoneUid)?.data.reduce(
        (acc, curr) => {
          acc.mins.push(roundToNDigits(curr.min));
          acc.maxs.push(roundToNDigits(curr.max));
          acc.means.push(roundToNDigits(curr.mean));
          acc.timestamps.push(curr.timestamp);
          return acc;
        },
        {
          mins: [] as number[],
          maxs: [] as number[],
          means: [] as number[],
          timestamps: [] as Date[],
        }
      ) ?? { mins: [], maxs: [], means: [], timestamps: [] };
      if (timeSeriesData.mins.length > 0) {
        zoneUidToPlantHeightTemp.set(zoneUid, {
          ...plantHeight,
          ...timeSeriesData,
        });
      }
    });

    setZoneUidToMinPlantHeight(zoneUidToPlantHeightTemp);
  }, [zoneUidToTimeSeriesData, loading, error, zoneUids]);
  return { zoneUidToPlantHeight, loading, error };
}

export default useGetPlantHeight;
