import { useMemo, useState } from 'react';
import { ESort, IConfig } from '../shared/interfaces';

type TNumberString = number | string;
/**
 * Returns an object containing the sorted items, a function to request a sort, the current sort configuration, and a function to update the sort configuration.
 *
 * @param {T[]} items - The array of items to be sorted.
 * @param {IConfig<T> | null} [config=null] - The configuration object for sorting. If null, a default configuration is used.
 * @returns {{ items: T[], requestSort: (key: string, valueMapFunction?: IConfig<T>['valueMapFunction']) => void, sortConfig: IConfig<T>, setSortConfig: (config: IConfig<T>) => void }} - An object containing the sorted items, a function to request a sort, the current sort configuration, and a function to update the sort configuration.
 */
export const useSortableData = <T>(
  items: T[],
  config: IConfig<T> | null = null
) => {
  const defaultConfig: IConfig<T> = {
    key: items && items[0] ? Object.keys(items[0])[0] : '',
    direction: ESort.DESCENDING,
    valueMapFunction: undefined,
  };

  const [sortConfig, setSortConfig] = useState<IConfig<T>>(
    config ?? defaultConfig
  );

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig) {
      sortableItems.sort(sortItems(sortConfig));
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (
    key: string,
    valueMapFunction: IConfig<T>['valueMapFunction'] = undefined
  ) => {
    const direction =
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === ESort.ASCENDING
        ? ESort.DESCENDING
        : ESort.ASCENDING;
    setSortConfig({
      key,
      direction,
      valueMapFunction,
    });
  };

  return { items: sortedItems, requestSort, sortConfig, setSortConfig };
};

/**
 * Generates a sorting function based on the given configuration.
 *
 * @param {IConfig} config - The configuration object for sorting.
 * @param {string} config.key - The key to sort the items by.
 * @param {ESort} config.direction - The sorting direction (ASCENDING or DESCENDING).
 * @param {Function} config.valueMapFunction - The function to map the values before comparing.
 * @returns {Function} A sorting function that can be used to sort an array of items.
 */
function sortItems<T>({ key, direction, valueMapFunction }: IConfig<T>) {
  return (a: T, b: T) => {
    if (!key || key === '') {
      return 0;
    }
    const valueA = getValue(a, key as keyof T, valueMapFunction);
    const valueB = getValue(b, key as keyof T, valueMapFunction);
    const directionMultiplier = direction === ESort.ASCENDING ? 1 : -1;

    return compareValues(valueA, valueB, directionMultiplier);
  };
}

/**
 * Retrieves the value from an object based on the provided key.
 *
 * @param {T} item - The object to retrieve the value from.
 * @param {K} key - The key to use for retrieving the value.
 * @param {"IConfig['valueMapFunction']"} valueMapFunction - An optional function
 *  that maps the value before returning it.
 * @returns {TNumberString} The retrieved value.
 */
function getValue<T, K extends keyof T>(
  item: T,
  key: K,
  valueMapFunction?: IConfig<T>['valueMapFunction']
): TNumberString {
  return valueMapFunction
    ? valueMapFunction(item)
    : (item[key] as TNumberString);
}

/**
 * Compares two values and returns a number based on the comparison.
 *
 * @param {TNumberString} a - The first value to compare.
 * @param {TNumberString} b - The second value to compare.
 * @param {number} directionMultiplier - The direction multiplier to apply to the result.
 * @returns {number} - A number representing the comparison result.
 */
function compareValues(
  a: TNumberString,
  b: TNumberString,
  directionMultiplier: number
): number {
  if (a < b) {
    return -1 * directionMultiplier;
  } else if (a > b) {
    return 1 * directionMultiplier;
  } else {
    return 0;
  }
}
