import { useAuth } from 'contexts';
import { FC, ReactNode } from 'react';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { Container, MainArea } from '../shared_layout/constants/';
import Header from './DesktopHeader';
import SideBar from './SideBar';

interface Props {
  children: ReactNode;
  headingText?: string;
}

const DesktopLayout: FC<Props> = ({ children }) => {
  const { isMobile } = useScreenSize();
  const { logout } = useAuth();

  return (
    <Container>
      <Header />
      <SideBar onClickLogOut={logout} />
      <MainArea isMobile={isMobile}>{children}</MainArea>
    </Container>
  );
};

export default DesktopLayout;
