import styled from 'styled-components';
import { Colors } from '../../../../shared/constants';
import { Typography } from '../../../common/Typography';
import { HEADER_HEIGHT, SIDEBAR_WIDTH } from '../constants';
import { IMainArea, IStyledHeader, ITopHeading } from '../interfaces';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  background-color: ${Colors.hb5};
  flex-direction: column;
`;

export const MainArea = styled.div<IMainArea>`
  flex: 1;
  min-width: 0;
  margin-left: ${({ isMobile }) => (isMobile ? '0' : SIDEBAR_WIDTH)}px;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  width: calc(
    100vw - ${({ isMobile }) => (isMobile ? '20' : SIDEBAR_WIDTH + 20)}px
  );
`;

export const StyledHeader = styled.div<IStyledHeader>`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : null)};
  height: ${HEADER_HEIGHT}px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: ${({ isMobile }) => (isMobile ? '0px' : '40px;')};
  padding-right: ${({ isMobile }) => (isMobile ? '0px' : '40px;')};
  width: 100%;
`;

export const TopHeading = styled(Typography)<ITopHeading>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '30px')};
  line-height: 40px;
  font-family: Poppins;
  font-weight: normal;
  margin-right: ${({ isMobile }) => (isMobile ? '0px' : '32px')};
  color: ${({ isMobile }) => (isMobile ? 'black' : null)};
`;
