import { useEffect } from 'react';

/**
 * A custom React hook that allows you to set the document title based on a state variable.
 *
 * @param {string} title - The initial title to set for the document.
 */
function useSetTitle(title = '') {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  });
}

export default useSetTitle;
