import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { forwardRef, useState } from 'react';

import { Colors, Sizes } from '../../shared/constants';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      label: {
        fontSize: 14,
      },

      input: {
        padding: `${Sizes.small}px 0`,
        fontSize: 18,
      },

      '& .MuiInput-underline::before': {
        borderBottom: '1px solid ${Colors.orange3}',
      },

      '& .MuiInput-underline::after': {
        borderBottom: `2px solid ${Colors.orange3}`,
      },
    },
  })
);

export type TTextInputProps = TextFieldProps;

/**
 * TextInput presentation component
 * Currently it's using Material-UI TextField under the hood.
 */
export const TextInput = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ type, ...props }, ref) => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordShow = () => setShowPassword((show) => !show);

    const endAdornment =
      type === 'password' ? (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleTogglePasswordShow}
            edge="end"
          >
            {showPassword ? (
              <VisibilityOffIcon data-testid="visibility-off-icon" />
            ) : (
              <VisibilityIcon data-testid="visibility-icon" />
            )}
          </IconButton>
        </InputAdornment>
      ) : null;

    return (
      <TextField
        data-testid="textField"
        className={classes.textField}
        ref={ref}
        variant="standard"
        InputProps={{
          endAdornment,
          inputProps: {
            'data-testid': 'input-button',
            'aria-autocomplete': 'none',
            autoComplete: 'off',
            autoCapitalize: 'off',
          },
        }}
        type={type === 'password' && showPassword ? 'default' : type}
        {...props}
      />
    );
  }
);

TextInput.displayName = 'TextInput';
