import { FieldNoteDto } from 'api/field_notes';
import { useState } from 'react';
import { IOptions } from 'shared/interfaces';
import { useSortableData } from './useSortableData';

export type TUseFieldNotesTableProps = {
  fieldNotes: FieldNoteDto[];
};

export const useFieldNotesTable = ({
  fieldNotes,
}: TUseFieldNotesTableProps) => {
  const [selectedSystems, setSelectedSystems] = useState<IOptions[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<IOptions[]>([]);
  const [selectedZones, setSelectedZones] = useState<IOptions[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<IOptions[]>([]);
  const [selectedSofwareVersions, setSelectedSofwareVersions] = useState<
    IOptions[]
  >([]);
  const [selectedHardwareVersions, setSelectedHardwareVersions] = useState<
    IOptions[]
  >([]);
  const {
    items: sortedSystemList,
    requestSort,
    sortConfig,
    setSortConfig,
  } = useSortableData<FieldNoteDto>(fieldNotes);
  const fieldNotesFilter = filterFunction({
    selectedCustomers,
    selectedSystems,
    selectedZones,
    selectedCategories,
    selectedSofwareVersions,
    selectedHardwareVersions,
  });
  const sortedAndFilteredFieldNotes = sortedSystemList.filter(fieldNotesFilter);

  return {
    selectedSystems,
    setSelectedSystems,
    selectedCustomers,
    setSelectedCustomers,
    selectedZones,
    setSelectedZones,
    selectedCategories,
    setSelectedCategories,
    selectedSofwareVersions,
    setSelectedSofwareVersions,
    selectedHardwareVersions,
    setSelectedHardwareVersions,
    sortConfig,
    setSortConfig,
    requestSort,
    sortedAndFilteredFieldNotes,
  };
};

/**
 * Returns a filter function that checks if a given system matches the selected criteria.
 *
 * @param {Object} options - The options object.
 * @param {IOptions[]} options.selectedSystems - An array of selected systems.
 * @param {IOptions[]} options.selectedCustomers - An array of selected customers.
 * @param {IOptions[]} options.selectedZones - An array of selected zones.
 * @param {IOptions[]} options.selectedCategories - An array of selected categories.
 * @returns {Function} - A filter function that takes a system and returns a boolean indicating
 *  if it matches the selected criteria.
 */
export function filterFunction({
  selectedSystems,
  selectedCustomers,
  selectedZones,
  selectedCategories,
  selectedSofwareVersions,
  selectedHardwareVersions,
}: {
  selectedSystems: IOptions[];
  selectedCustomers: IOptions[];
  selectedZones: IOptions[];
  selectedCategories: IOptions[];
  selectedSofwareVersions: IOptions[];
  selectedHardwareVersions: IOptions[];
}) {
  return (fieldNote: FieldNoteDto) => {
    const hasSystem =
      selectedSystems.length === 0 ||
      selectedSystems.some(
        ({ label }) => label === fieldNote.system.systemUid.toString()
      );
    const hasCustomer =
      selectedCustomers.length === 0 ||
      selectedCustomers.some(
        ({ label }) => label === fieldNote.system.customerName
      );
    const hasZone =
      selectedZones.length === 0 ||
      selectedZones.some(({ label }) => label === fieldNote.system.zoneName);
    const hasCategory =
      selectedCategories.length === 0 ||
      selectedCategories.some(({ label }) => label === fieldNote.category);

    const hasSofwareVersion =
      selectedSofwareVersions.length === 0 ||
      selectedSofwareVersions.some(
        ({ label }) => label === fieldNote.system.softwareVersion
      );
    const hasHardwareVersion =
      selectedHardwareVersions.length === 0 ||
      selectedHardwareVersions.some(
        ({ label }) => label === fieldNote.system.hardwareVersion
      );
    return (
      hasSystem &&
      hasCustomer &&
      hasZone &&
      hasCategory &&
      hasSofwareVersion &&
      hasHardwareVersion
    );
  };
}
