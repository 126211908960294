import { Switch } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { FieldNoteDto, useGetFieldNotes } from 'api/field_notes';
import { formatDateInFullDayTime } from 'api/system_logs';
import { headerMessageVar, showAllSystemsVar } from 'cache';
import { DateSelector, Filter, SelectBox, Typography } from 'components/common';
import DesktopLayout from 'components/layout/desktop_layout/DesktopLayout';
import { StyledLink } from 'components/system_details/LogItem';
import { useTimeframeContext } from 'contexts';
import { useFieldNotesTable } from 'hooks/useFieldNotesTable';
import { useCallback, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { ESystemColumn } from 'shared/interfaces';
import { createSetSelectedFn, getDashboardUrl } from 'shared/utils';

import { useReactiveVar } from '@apollo/client';
import { useAppConfig } from 'contexts/AppConfigProvider';
import { format, minutesToMilliseconds, sub } from 'date-fns';
import useSetTitle from 'hooks/useSetTitle';
import { versionToNumber } from 'shared/utils/utils';
import styled from 'styled-components';
import InterventionByZoneBarChart from './InterventionsByZoneBarChart';
const FieldReport = () => {
  const { appConfig } = useAppConfig();
  const { now, startDate, endDate, setStartDate, setEndDate } =
    useTimeframeContext();
  const showAllSystems = useReactiveVar(showAllSystemsVar);

  const {
    data: fieldNotes,
    loading,
    error,
  } = useGetFieldNotes({
    startDate,
    endDate,
    isFetchAllSystems: showAllSystems,
  });
  useSetTitle(
    `Field Report ${format(startDate, 'MMM-dd')} - ${format(endDate, 'MMM-dd')}`
  );
  const {
    sortConfig,
    setSelectedSystems,
    selectedSystems,
    setSelectedCustomers,
    selectedCustomers,
    setSelectedZones,
    selectedZones,
    setSelectedCategories,
    selectedCategories,
    selectedSofwareVersions,
    setSelectedSofwareVersions,
    selectedHardwareVersions,
    setSelectedHardwareVersions,
    requestSort,
    sortedAndFilteredFieldNotes,
  } = useFieldNotesTable({ fieldNotes });

  if (error) {
    console.error(error);
  }
  const [isGroupByweek, setIsGroupByweek] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setStartDate(sub(now, { days: 7 }));
      setEndDate(now);
    }, minutesToMilliseconds(30));

    setStartDate(sub(now, { days: 7 }));
    setEndDate(now);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStartDate, setEndDate]);

  const getSortDirection = useCallback(
    (name: string) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    },
    [sortConfig]
  );

  if (loading) {
    return <div>{'Loading'}</div>;
  }

  const systemSelectBox = (
    <SelectBox
      options={fieldNotes
        .map((fieldNote) => fieldNote.system.systemUid.toString())
        .sort()}
      handleSelect={createSetSelectedFn(setSelectedSystems)}
      columnTitle={'Filter System'}
      isMobile={true}
      selectedValues={selectedSystems}
    />
  );
  const customerSelectBox = (
    <SelectBox
      options={fieldNotes
        .map((fieldNote) => fieldNote.system.customerName.toString())
        .sort()}
      handleSelect={createSetSelectedFn(setSelectedCustomers)}
      columnTitle={'Filter Customers'}
      isMobile={true}
      selectedValues={selectedCustomers}
    />
  );
  const zoneSelectBox = (
    <SelectBox
      options={fieldNotes
        .map((fieldNote) => fieldNote.system.zoneName.toString())
        .sort()}
      handleSelect={createSetSelectedFn(setSelectedZones)}
      columnTitle={'Filter Zones'}
      isMobile={true}
      selectedValues={selectedZones}
    />
  );
  const categorySelectBox = (
    <SelectBox
      options={fieldNotes
        .map((fieldNote) => fieldNote.category.toString())
        .sort()}
      handleSelect={createSetSelectedFn(setSelectedCategories)}
      columnTitle={'Filter Categories'}
      isMobile={true}
      selectedValues={selectedCategories}
    />
  );
  const softwareVersionSelectBox = (
    <SelectBox
      options={fieldNotes
        .map((fieldNote) => fieldNote.system.softwareVersion)
        .sort((a, b) => {
          const aNumber = versionToNumber(a);
          const bNumber = versionToNumber(b);
          return bNumber - aNumber;
        })}
      handleSelect={createSetSelectedFn(setSelectedSofwareVersions)}
      columnTitle={'Filter Software Versions'}
      isMobile={true}
      selectedValues={selectedSofwareVersions}
    />
  );
  const hardwareVersionSelectBox = (
    <SelectBox
      options={fieldNotes
        .map((fieldNote) => fieldNote.system.hardwareVersion)
        .sort((a, b) => {
          const aNumber = versionToNumber(a);
          const bNumber = versionToNumber(b);
          return bNumber - aNumber;
        })}
      handleSelect={createSetSelectedFn(setSelectedHardwareVersions)}
      columnTitle={'Filter Hardware Versions'}
      isMobile={true}
      selectedValues={selectedHardwareVersions}
    />
  );
  const columns: TableColumn<FieldNoteDto>[] = [
    {
      name: (
        <Filter
          columnTitle="System"
          onSort={() =>
            requestSort(ESystemColumn.SystemUid, (fieldNote) =>
              fieldNote.system.systemUid.toString()
            )
          }
          direction={getSortDirection(ESystemColumn.SystemUid)}
        ></Filter>
      ),
      cell: (row) => {
        const url = `/system/${row.system.systemUid}`;

        return <StyledLink to={url}>{row.system.systemUid}</StyledLink>;
      },
    },
    {
      name: (
        <Filter
          columnTitle="Customer"
          onSort={() =>
            requestSort(ESystemColumn.Customer, (fieldNote) =>
              fieldNote.system.customerName.toString()
            )
          }
          direction={getSortDirection(ESystemColumn.Customer)}
        ></Filter>
      ),
      selector: (row) => row.system.customerName,
    },
    {
      name: (
        <Filter
          columnTitle="Zone"
          onSort={() =>
            requestSort(ESystemColumn.Zone, (fieldNote) =>
              fieldNote.system.zoneName.toString()
            )
          }
          direction={getSortDirection(ESystemColumn.Zone)}
        ></Filter>
      ),
      cell: (row) => {
        const dashboardUrl = getDashboardUrl(
          appConfig.dashboardBaseUrl,
          row.system.customerCode,
          row.system.zoneId
        );
        return (
          <a href={dashboardUrl} target="_blank" rel="noopener noreferrer">
            {row.system.zoneName}
          </a>
        );
      },
    },
    {
      name: (
        <Filter
          columnTitle="Updated at"
          onSort={() =>
            requestSort(ESystemColumn.SpyderStatusUpdatedAt, (fieldNote) =>
              fieldNote.updatedAt.getTime()
            )
          }
          direction={getSortDirection(ESystemColumn.SpyderStatusUpdatedAt)}
        ></Filter>
      ),
      selector: (row) => formatDateInFullDayTime(row.updatedAt),
    },
    {
      name: (
        <Filter
          columnTitle="Note"
          onSort={() =>
            requestSort('notes', (fieldNote) => fieldNote.notes.title)
          }
          direction={getSortDirection('notes')}
        ></Filter>
      ),
      selector: (row) => row.notes.title,
    },
    {
      name: (
        <Filter
          columnTitle="Category"
          onSort={() =>
            requestSort('category', (fieldNote) => fieldNote.category)
          }
          direction={getSortDirection('category')}
        ></Filter>
      ),
      selector: (row) => row.notes.category,
    },
    {
      name: (
        <Filter
          columnTitle="sw version"
          onSort={() =>
            requestSort('softwareVersion', (fieldNote) =>
              versionToNumber(fieldNote.system.softwareVersion)
            )
          }
          direction={getSortDirection('softwareVersion')}
        ></Filter>
      ),
      selector: (row) =>
        row.notes.softwareVersion ?? row.system.softwareVersion + '*',
    },
    {
      name: (
        <Filter
          columnTitle="hw version"
          onSort={() =>
            requestSort('hardwareVersion', (fieldNote) =>
              versionToNumber(fieldNote.system.hardwareVersion)
            )
          }
          direction={getSortDirection('hardwareVersion')}
        ></Filter>
      ),
      selector: (row) =>
        row.notes.hardwareVersion ?? row.system.hardwareVersion + '*',
    },
  ];
  let table = <></>;
  if (fieldNotes.length > 0 && sortedAndFilteredFieldNotes.length === 0) {
    table = (
      <Typography variant="body2">
        Systems available but all are filtered out. Try adjusting filters.
        <span>
          {formatDateInFullDayTime(startDate)} -{' '}
          {formatDateInFullDayTime(endDate)}
        </span>
      </Typography>
    );
  } else {
    table = (
      <DataTable
        columns={columns}
        data={sortedAndFilteredFieldNotes}
        persistTableHead={true}
        striped
        dense
      />
    );
  }
  const handleSwitchClick = () => {
    showAllSystemsVar(!showAllSystemsVar());
  };
  headerMessageVar(
    `Field Report: ${formatDateInFullDayTime(startDate)} - ${formatDateInFullDayTime(endDate)}`
  );
  return (
    <>
      <DesktopLayout>
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            marginBottom: '20px',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateSelector
              startDateValue={startDate}
              endDateValue={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </LocalizationProvider>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            marginBottom: '20px',
          }}
        >
          <div style={{ width: '200px', marginRight: '20px' }}>
            {systemSelectBox}
          </div>
          <div style={{ width: '200px', marginRight: '20px' }}>
            {customerSelectBox}
          </div>
          <div style={{ width: '200px', marginRight: '20px' }}>
            {zoneSelectBox}
          </div>
          <div style={{ width: '200px', marginRight: '20px' }}>
            {categorySelectBox}
          </div>
          <div style={{ width: '200px', marginRight: '20px' }}>
            {softwareVersionSelectBox}
          </div>
          <div style={{ width: '200px', marginRight: '20px' }}>
            {hardwareVersionSelectBox}
          </div>
          <div style={{ width: '200px', marginRight: '20px' }}>
            <Typography variant="body4" color="gray">
              Group by week
            </Typography>
            <Switch
              checked={isGroupByweek}
              onChange={() => setIsGroupByweek(!isGroupByweek)}
            />
          </div>
          <div style={{ width: '200px', marginRight: '20px' }}>
            <Typography variant="body4" color="gray">
              Include inactive systems
            </Typography>
            <Switch checked={showAllSystems} onChange={handleSwitchClick} />
          </div>
        </div>
        <div>
          <InterventionByZoneBarChart
            data={sortedAndFilteredFieldNotes}
            isGroupByweek={isGroupByweek}
            style={{ height: '600px' }}
          />
        </div>
        <OverviewContainer>
          <div>{table}</div>
        </OverviewContainer>
      </DesktopLayout>
    </>
  );
};

export default FieldReport;

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  .rdt_TableCol_Sortable {
    overflow: visible;
  }
`;
