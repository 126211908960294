import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';
import styled from 'styled-components';

export const CenteredLoader: FC = () => {
  return (
    <Container>
      <CircularProgress data-testid="icon" />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;
