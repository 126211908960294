import { FC } from 'react';

import { createStyles, makeStyles } from '@mui/styles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TDialogProps,
  Typography,
} from 'components/common';
import { useScreenSize } from 'hooks';

const useStyles = makeStyles((theme) =>
  createStyles({
    mobileScrollPaper: {
      alignItems: 'flex-end',
    },
    mobilePaper: {
      margin: 0,
      width: '100vw',
    },
    dialog: {
      '.MuiDialog-paper': {
        borderRadius: theme.spacing(4),
      },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
      marginTop: theme.spacing(4),
      padding: theme.spacing(1, 4),
      width: ({ isMobile }: { isMobile: boolean }) => (isMobile ? '80vw' : 520),
    },
    actions: {
      display: 'flex',
      alignItems: 'stretch',
      padding: theme.spacing(4),
    },
  })
);

export type TFormDialogVariant = 'success' | 'error';
interface VoidFunction {
  (): void;
}

interface IFormDialogProps extends Omit<TDialogProps, 'title'> {
  /** Save button text */
  saveText?: string;
  /** The close button text. */
  closeText?: string;
  /** The dialog title to be shown in the dialog. */
  title?: string;
  /** Callback to be called when the dialog closes. */
  onCloseAction: VoidFunction;
  /** Callback to be called when the save button is clicked */
  onSave: VoidFunction;
  showSaveButton?: boolean;
  children: React.ReactNode;
}

export const FormDialog: FC<IFormDialogProps> = ({
  saveText = 'Save',
  closeText = 'Close',
  title = '',
  open,
  onCloseAction,
  onSave,
  showSaveButton,
  children,
}) => {
  const { isMobile } = useScreenSize();
  const classes = useStyles({ isMobile });

  return (
    <Dialog
      aria-label={title}
      className={classes.dialog}
      classes={{
        scrollPaper: isMobile ? classes.mobileScrollPaper : undefined,
        paper: isMobile ? classes.mobilePaper : undefined,
      }}
      open={open}
      onClose={onCloseAction}
      data-testid="dialog"
    >
      <DialogContent className={classes.content}>
        <Typography variant="h6">{title}</Typography>
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color="secondary" fullWidth onClick={onCloseAction}>
          {closeText}
        </Button>
        {showSaveButton && (
          <Button color="primary" fullWidth onClick={onSave}>
            {saveText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
