import { ApolloError } from '@apollo/client';
import { useGetSystemDetailsQuery } from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import validator from 'validator';
import { z } from 'zod';

/**
 * Checks if the given URL is valid or empty.
 *
 * @param {string} url - The URL to be checked.
 * @returns {boolean} Returns true if the URL is empty or valid, false otherwise.
 */
function isUrlOrEmpty(url: string) {
  if (url.length === 0) {
    return true;
  }
  return validator.isURL(url) && url.indexOf('http') === 0;
}
const urlOrEmptyMessage = {
  message: 'Please enter a valid URL starting with http or leave it empty.',
};
export const SystemMetadataSchema = z.object({
  filter_type: z.string().trim().optional(),
  notes: z.string().optional(),
  cockpit_url: z
    .string()
    .trim()
    .refine(isUrlOrEmpty, urlOrEmptyMessage)
    .optional(),
  ring0_url: z
    .string()
    .trim()
    .refine(isUrlOrEmpty, urlOrEmptyMessage)
    .optional(),
  ring1_url: z
    .string()
    .trim()
    .refine(isUrlOrEmpty, urlOrEmptyMessage)
    .optional(),
  ring2_url: z
    .string()
    .trim()
    .refine(isUrlOrEmpty, urlOrEmptyMessage)
    .optional(),
  ring3_url: z
    .string()
    .trim()
    .refine(isUrlOrEmpty, urlOrEmptyMessage)
    .optional(),
  web: z.string().startsWith('WEB_').toUpperCase().optional(),
  updated_on_read_only: z.string().optional(),
  updated_by_read_only: z.string().optional(),
  misc: z.record(z.string()).optional(),
});

// If you need to use this schema as a type, you can also extract the type from the schema
export type ISystemMetadata = z.infer<typeof SystemMetadataSchema>;

/**
 * AuthContext definition
 */
export interface ISystemDetailsContextType {
  systemId: number;
  zoneId: number;
  zoneUid: string;
  zoneName: string;
  organizationId: number;
  organizationName: string;
  organizationCode: string;
  systemUid: string;
  metadata: ISystemMetadata;
  isActive: boolean;
  isMetadataInitialized: boolean;
  systemDetailLoading: boolean;
  systemDetailError: ApolloError | undefined;
  softwareVersion: string;
  hardwareVersion: string;
}

export const SystemDetailsContext = createContext<ISystemDetailsContextType>(
  undefined!
);

export const SystemDetailsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { systemUid } = useParams();
  const {
    data: systemDetailData,
    error: systemDetailError,
    loading: systemDetailLoading,
  } = useGetSystemDetailsQuery({
    variables: {
      uid: systemUid || 'NO_SYSTEM_UID_SET',
    },
  });
  const system = systemDetailData?.system[0];
  const systemId = useMemo(() => {
    return system?.id || -1;
  }, [system]);

  const organizationId = useMemo(() => {
    return system?.organization_id || -1;
  }, [system]);

  const zoneId = useMemo(() => {
    return system?.zone_id || -1;
  }, [system]);
  const zoneUid = useMemo(() => {
    return system?.zone.uid || 'NO_ZONE_UID_SET';
  }, [system]);
  const organizationName = useMemo(() => {
    return (
      system?.organization.enumeration.description ||
      'Organization name unknown'
    );
  }, [system]);

  const organizationCode = useMemo(() => {
    return system?.organization.enumeration.code || 'Organization code unknown';
  }, [system]);

  const isMetadataInitialized = useMemo(() => {
    return !isNil(system?.metadata);
  }, [system]);

  const metadata = useMemo(() => {
    return system?.metadata || {};
  }, [system]);

  const isActive = useMemo(() => {
    return system?.is_active || false;
  }, [system]);
  const softwareVersion = useMemo(() => {
    if (isNil(system)) {
      return 'NO_SOFTWARE_VERSION_SET';
    }
    return `${system.softwareVersion.major}.${system.softwareVersion.minor}.${system.softwareVersion.patch}`;
  }, [system]);
  const hardwareVersion = useMemo(() => {
    if (isNil(system)) {
      return 'NO_HARDWARE_VERSION_SET';
    }
    return `${system.hardwareVersion.major}.${system.hardwareVersion.minor}.${system.hardwareVersion.patch}`;
  }, [system]);
  return (
    <SystemDetailsContext.Provider
      value={{
        softwareVersion,
        hardwareVersion,
        systemId,
        zoneId,
        organizationId,
        organizationName,
        organizationCode,
        systemUid: systemUid || 'NO_SYSTEM_UID_SET',
        metadata: metadata,
        isActive,
        isMetadataInitialized,
        zoneName: system?.zone.enumeration.description || '',
        systemDetailError,
        systemDetailLoading,
        zoneUid,
      }}
    >
      {children}
    </SystemDetailsContext.Provider>
  );
};

export const useSystemDetails = () => {
  return useContext(SystemDetailsContext);
};
