import { createStyles, makeStyles } from '@mui/styles';
import { useCallback } from 'react';
import { toast as defaultToast, ToastOptions } from 'react-toastify';

const DEFAULT_NON_SUCCESS_TOAST_TIME = 5000;
const DEFAULT_SUCCESS_TOAST_TIME = 3000;

const useStyles = makeStyles((theme) =>
  createStyles({
    toastProgressBar: {
      background: `${theme.palette.primary.main} !important`,
    },
  })
);

export const useToast = () => {
  const classes = useStyles();

  const toast = useCallback(
    (content: string, options?: ToastOptions) => {
      const autoClose =
        options?.type === 'warning' || options?.type === 'error'
          ? DEFAULT_NON_SUCCESS_TOAST_TIME
          : DEFAULT_SUCCESS_TOAST_TIME;

      defaultToast(content, {
        autoClose,
        position: 'bottom-right',
        progressClassName: classes.toastProgressBar,
        type: 'success',
        ...options,
      });
    },
    [classes.toastProgressBar]
  );

  return { toast };
};
