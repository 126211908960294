import { useAuth } from 'contexts';
import { FC } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { APP_URL } from 'shared/constants';
import { EAuthStatus } from 'shared/interfaces';

export const ProtectedRoute: FC<RouteProps> = ({ children }) => {
  const { authStatus } = useAuth();

  if (authStatus === EAuthStatus.LOGGED_OUT) {
    return <Navigate to={{ pathname: APP_URL.login }} />;
  }

  return <>{children}</>;
};
