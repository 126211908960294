import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import { createStyles, makeStyles } from '@mui/styles';
import LogoutIcon from 'assets/svg/logout.svg?react';
import PlantHeightIcon from 'assets/svg/plant-height.svg?react';
import { useNavigate } from 'react-router-dom';
import { APP_URL } from 'shared/constants';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { IconButton } from '../../common/IconButton';
import { SIDEBAR_WIDTH } from '../shared_layout/constants/headerSidebar';
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'absolute',
      top: 120,
      bottom: 0,
      width: SIDEBAR_WIDTH,
      paddingBottom: 64,
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

interface ISideBarProps {
  onClickLogOut: VoidFunction;
}

const SideBar: React.FC<ISideBarProps> = ({ onClickLogOut }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  if (isMobile) {
    return null;
  }
  return (
    <div className={classes.container}>
      <IconButton
        onClick={() => {
          navigate(APP_URL.systems);
        }}
      >
        <HomeIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          navigate(APP_URL.reports);
        }}
      >
        <ListIcon />
      </IconButton>{' '}
      <IconButton
        onClick={() => {
          navigate(APP_URL.plantHeight);
        }}
      >
        <PlantHeightIcon />
      </IconButton>
      <IconButton
        onClick={onClickLogOut}
        style={{
          position: 'absolute',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <LogoutIcon />
      </IconButton>
    </div>
  );
};

export default SideBar;
