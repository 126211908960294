import { minutesToMilliseconds } from 'date-fns';
import { Colors } from './color';

export const lastRunThresholdMilliseconds = minutesToMilliseconds(60);
export const lastLogThresholdMilliseconds = minutesToMilliseconds(15);
export const systemUpdateMilliseconds = minutesToMilliseconds(1);
export const systemTimeframeMinutes = 15;

export const nominalBackground = Colors.greenTransparent1;
export const nominalText = Colors.black;
export const warningBackground = Colors.orangeTransparent2;
export const warningText = Colors.black;
export const errorBackground = Colors.redTransparent1;
export const errorText = Colors.black;
export const staticBackground = Colors.orangeTransparent1;
export const staticText = Colors.black;

export const REFRESH_TIMEOUT_MINUTES = 1;
