import { SxProps, Theme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
  addDays,
  endOfDay,
  isAfter,
  isBefore,
  isValid,
  startOfDay,
} from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { Colors } from 'shared/constants';

import styled from 'styled-components';

interface IDateSelector {
  startDateValue: Date;
  endDateValue: Date;
  setStartDate: Dispatch<SetStateAction<Date>>;
  setEndDate: Dispatch<SetStateAction<Date>>;
  isMobile?: boolean;
}

export const DateSelector = ({
  startDateValue,
  endDateValue,
  setStartDate,
  setEndDate,
  isMobile,
}: IDateSelector) => {
  const handleSetStartDate = (newDate: Nullable<Date>) => {
    const earliestRecordedDataYear = 2018;
    if (
      newDate &&
      isValid(newDate) &&
      newDate.getFullYear() >= earliestRecordedDataYear
    ) {
      setStartDate(startOfDay(newDate));
    }
  };

  const handleSetEndDate = (newDate: Nullable<Date>) => {
    const latestPossibleDate = addDays(startOfDay(new Date()), 1);
    if (newDate && isValid(newDate) && newDate <= latestPossibleDate) {
      setEndDate(endOfDay(newDate));
    }
  };

  const handleSetStartTime = (date: Nullable<Date>) => {
    if (!date || isAfter(date, endDateValue)) return;
    setStartDate(date);
  };

  const handleSetEndTime = (date: Nullable<Date>) => {
    if (!date || isBefore(date, startDateValue)) return;
    setEndDate(date);
  };

  const renderDateContainer = (
    title: string,
    date: Date,
    setDate: (newDate: Nullable<Date>) => void,
    setTime: (date: Nullable<Date>) => void,
    testIdText: string
  ) => {
    return (
      <SectionRow id="SectionRow">
        <DateLabel id="DateLabel">
          <DateTypography isMobile={isMobile}>{title}</DateTypography>
        </DateLabel>
        <DateItem id="DateItem-date">
          <DatePicker
            value={date}
            onChange={setDate}
            slotProps={{
              textField: {
                sx: dateTimeStyledInput,
                inputProps: {
                  'data-testid': `${testIdText}-date`,
                },
              },
            }}
          />
        </DateItem>
        <DateItem id="DateItem-time" style={{ marginLeft: 'auto' }}>
          <TimePicker
            value={date}
            onChange={setTime}
            slotProps={{
              textField: {
                sx: dateTimeStyledInput,
                inputProps: {
                  'data-testid': `${testIdText}-time`,
                },
              },
            }}
            ampm={false}
          />
        </DateItem>
      </SectionRow>
    );
  };

  return (
    <DateContainer id="DateContainer">
      {renderDateContainer(
        'Start Date:',
        startDateValue,
        handleSetStartDate,
        handleSetStartTime,
        'start'
      )}
      {renderDateContainer(
        'End Date:',
        endDateValue,
        handleSetEndDate,
        handleSetEndTime,
        'end'
      )}
    </DateContainer>
  );
};

interface IDateTypography {
  isMobile?: boolean;
}

const dateTimeStyledInput: SxProps<Theme> = {
  marginRight: -50,
  fontFamily: 'Poppins',
  fontSize: 16,
  fontWeight: 200,
  width: 170,
  '& .MuiInputBase-input': {
    padding: 1.5,
  },
  '& .MuiOutlinedInput-root': {
    color: Colors.dark1,
    borderRadius: 4,
    '& fieldset': {
      borderColor: Colors.dark7,
    },
    '&:hover fieldset': {
      borderColor: Colors.dark7,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.black,
    },
  },
};

const DateContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 10,
  flexWrap: 'wrap',
});

const SectionRow = styled.div({
  display: 'flex',
  alignContent: 'flex-start',
  gap: 10,
  alignItems: 'center',
  flexWrap: 'wrap',
});

const DateLabel = styled.div({ flex: 1, minWdith: 100 });
const DateItem = styled.div({ marginLeft: 'auto', minWidth: 170 });

const DateTypography = styled.div<IDateTypography>`
  display: flex;
  font-size: ${({ isMobile }) => (isMobile ? '12px' : undefined)};
`;
