/**
 * Checks if the given value is between the start and end values.
 *
 * @param {number} value - the value to check
 * @param {number} start - the start value
 * @param {number} end - the end value
 * @returns {boolean} true if the value is between start and end, false otherwise
 */
export function isBetween(value: number, start: number, end: number) {
  return start <= value && value <= end;
}

/**
 * Returns the username part of the given email address.
 *
 * @param {string} email - the email address to extract the username from
 * @returns {string} the username part of the email address
 */
export function shortenUsername(email: string) {
  return email.split('@')[0] || '';
}

/**
 * Shortens the format distance by replacing certain strings with shorter versions.
 *
 * @param {string} time - The time string to be shortened.
 * @returns {string} The shortened time string.
 */
export function shortenFormatDistance(time: string) {
  return time
    .replace('less than a minute', '< 1 min')
    .replace('minute', 'min')
    .replace('hour', 'hr')
    .replace('day', 'd')
    .replace('about', '~');
}

/**
 * A function that rounds a number to a specified number of decimal digits.
 *
 * @param {number} num - the number to be rounded
 * @param {number} digits - the number of decimal digits to round to
 * @returns {number} the rounded number
 */
export function roundToNDigits(num: number, digits: number = 2) {
  const factor = Math.pow(10, digits);
  return Math.round(num * factor) / factor;
}

/**
 * Converts a version string into a numerical representation.
 *
 * @param {string} version - The version string to convert.
 * @returns {number} The numerical representation of the version.
 */
export function versionToNumber(version: string) {
  const [major, minor, patch] = version.split('.');
  let factor = parseInt(patch);
  const minorFactor = 1_000;
  factor += parseInt(minor) * minorFactor;
  const majorFactor = 1_000_000;
  factor += parseInt(major) * majorFactor;
  return factor;
}
