import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { ReactNode, forwardRef, useCallback } from 'react';

type TDropdownValue = string | number;

export type TDropdownOption = {
  id: TDropdownValue;
  label: string;
};

export interface IDropdownProps extends Omit<SelectProps, 'onChange'> {
  className?: string;
  iconOnly?: boolean;
  menuItemClassName?: string;
  MenuIcon?: ReactNode;
  onChange: (value: string) => void;
  options: TDropdownOption[];
  testId?: string;
  value: TDropdownValue;
}

export const Dropdown = forwardRef<HTMLSelectElement, IDropdownProps>(
  ({ value, onChange, options, testId = 'select' }, ref) => {
    const handleChange = useCallback(
      (event: SelectChangeEvent<string | number>) => {
        onChange(event.target.value + '');
      },
      [onChange]
    );
    return (
      <Select
        data-testid={testId}
        onChange={handleChange}
        ref={ref}
        value={value}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            value={option.label}
            data-testid={`${testId}-${option.id}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  }
);

Dropdown.displayName = 'Dropdown';
