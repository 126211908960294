import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Colors, Fonts } from '../../shared/constants';

interface ITypographyProps extends HTMLAttributes<HTMLSpanElement> {
  /**
   * className to be applied to the component.
   */
  className?: string;
  /**
   * Color of text to be displayed.
   *  default => black
   *  gray => gray
   */
  color?: string | 'gray';
  /**
   * Variant of fonts to be used.
   */
  variant: keyof typeof Fonts;
  /**
   * Font style to be applied directly.
   */
  fontWeight?: string;
  /**
   * Line height to be applied.
   */
  lineHeight?: number | string;
  /**
   * Margin to be applied.
   */
  margin?: string | number;
  /**
   * Font size to be applied.
   */
  fontSize?: number;
}

export const Typography: FC<ITypographyProps> = ({
  className,
  children,
  color,
  fontWeight,
  lineHeight,
  variant,
  margin,
  fontSize,
  ...props
}) => {
  return (
    <StyledSpan
      className={className}
      color={color}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      variant={variant}
      margin={margin}
      fontSize={fontSize}
      {...props}
    >
      {children}
    </StyledSpan>
  );
};

interface IStyledSpanProps
  extends Pick<
    ITypographyProps,
    'fontWeight' | 'variant' | 'lineHeight' | 'margin' | 'fontSize'
  > {}

const StyledSpan = styled.span<IStyledSpanProps>`
  color: ${(props) => (props.color === 'gray' ? Colors.dark7 : props.color)};
  font-family: Poppins;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : Fonts[props.variant].size}px;
  font-weight: ${(props) => props.fontWeight || Fonts[props.variant].weight};
  font-style: ${(props) => Fonts[props.variant].style};
  line-height: ${(props) => props.lineHeight || 1.25};
  margin: ${(props) => (props.margin ? props.margin : undefined)};
`;
