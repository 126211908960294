import { subMinutes } from 'date-fns';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { systemTimeframeMinutes } from 'shared/constants';

/**
 * AuthContext definition
 */
export interface ITimeframeContextType {
  startDate: Date;
  endDate: Date;
  now: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
}

export const TimeframeContext = createContext<ITimeframeContextType>(
  undefined!
);

export const TimeframeProvider = ({ children }: { children: ReactNode }) => {
  const now = new Date();
  const [startDate, setStartDate] = useState(
    subMinutes(now, systemTimeframeMinutes)
  );
  const [endDate, setEndDate] = useState(now);

  return (
    <TimeframeContext.Provider
      value={{
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        now,
      }}
    >
      {children}
    </TimeframeContext.Provider>
  );
};

export const useTimeframeContext = () => {
  return useContext(TimeframeContext);
};
