import { CircularProgress } from '@mui/material';
import { FC, useRef } from 'react';
import { PaginationComponentProps } from 'react-data-table-component';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

export const InfiniteScroll: FC<PaginationComponentProps> = ({
  rowsPerPage,
  rowCount,
  currentPage,
  onChangeRowsPerPage,
}) => {
  const observedContainer = useRef(null);
  const atEndOfPage = rowsPerPage >= rowCount;
  const increaseByNum = 20;
  const increaseRows = useDebouncedCallback(() => {
    onChangeRowsPerPage(rowsPerPage + increaseByNum, currentPage);
  }, 500);

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        } else {
          increaseRows();
        }
      });
    },
    {
      rootMargin: '0% 0% 10%',
    }
  );

  if (observedContainer.current) {
    observer.observe(observedContainer.current);

    if (atEndOfPage) observer.disconnect();
  }

  return (
    <PaginationWrapper data-testid="infinite-scroll">
      {!atEndOfPage && (
        <div data-testid="observedContainer" ref={observedContainer}>
          <CircularProgress />
        </div>
      )}
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled.nav`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
`;
