import { ApolloClient, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import { cache } from 'cache';
import { CenteredLoader } from 'components/common';
import { AuthProvider, DynamoDBProvider, TimeframeProvider } from 'contexts';
import { AppConfigProvider } from 'contexts/AppConfigProvider';
import { FC, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from 'Routes';
import './App.css';
import { createAppConfig } from './shared/config/create-app-config';
import theme from './theme';

declare global {
  interface Window {
    NEATLEAF_APP_CONFIG: Record<string, string>;
  }
}

const appConfig = createAppConfig(window.NEATLEAF_APP_CONFIG);
console.info(appConfig);

const awsAmplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: appConfig.aws.cognito.identityPoolId,
      userPoolClientId: appConfig.aws.cognito.webClientId,
      userPoolId: appConfig.aws.cognito.userPoolId,
    },
  },
};

Amplify.configure(awsAmplifyConfig);

const httpLink = createHttpLink({
  uri: appConfig.hasura.graphqlEndpoint.toString(),
});

const authLink = setContext(async (_, { headers }) => {
  const token = (await fetchAuthSession()).tokens?.idToken?.toString();
  if (!token) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
});
export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

/**
 * Return table with list of systems
 * @returns {JSX.Element} App component
 */
const App: FC = (): JSX.Element => {
  return (
    <AppConfigProvider appConfig={appConfig}>
      <ApolloProvider client={client}>
        <StyledEngineProvider injectFirst>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={theme}>
              <Suspense fallback={<CenteredLoader />}>
                <Router>
                  <AuthProvider>
                    <DynamoDBProvider region={appConfig.aws.dynamoDb.region}>
                      <TimeframeProvider>
                        <Routes />
                      </TimeframeProvider>
                    </DynamoDBProvider>
                  </AuthProvider>
                </Router>
              </Suspense>
              <ToastContainer position="bottom-right" />
            </ThemeProvider>
          </CacheProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    </AppConfigProvider>
  );
};

export default App;
