import { useReactiveVar } from '@apollo/client';
import {
  selectedCategoriesVar,
  selectedCustomersVar,
  selectedHardwareVersionsVar,
  selectedSoftwareVersionsVar,
  selectedSystemsVar,
  selectedZonesVar,
} from 'cache';
import { SelectBox } from 'components/common';
import { IOptions } from 'shared/interfaces';
import { createSetSelectedFn } from 'shared/utils';
import { versionToNumber } from 'shared/utils/utils';

interface Props {
  children: JSX.Element[];
}

/**
 * Renders a container for a group of filters.
 *
 * @param {Props} props - The properties for the component.
 * @param {JSX.Element[]} props.children - The filters to be rendered.
 * @returns {JSX.Element} The rendered container.
 */
function FiltersRoot(props: Props) {
  const { children } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '20px',
      }}
    >
      {children.map((child, index) => (
        <div key={index} style={{ minWidth: '200px' }}>
          {child}
        </div>
      ))}
    </div>
  );
}

interface SystemsProps {
  systemUids: string[];
}

const Systems: React.FC<SystemsProps> = ({
  systemUids,
}: SystemsProps): JSX.Element => {
  const selectedSystems = useReactiveVar(selectedSystemsVar);
  const setSelectedSystems = (options: IOptions[]) => {
    selectedSystemsVar(options);
  };
  return (
    <div>
      <SelectBox
        options={systemUids.sort()}
        handleSelect={createSetSelectedFn(setSelectedSystems)}
        columnTitle={'System'}
        isMobile={true}
        selectedValues={selectedSystems}
      />
    </div>
  );
};

const Zones = ({ zoneNames }: { zoneNames: string[] }): JSX.Element => {
  const selectedZones = useReactiveVar(selectedZonesVar);
  const setSelectedZones = (options: IOptions[]) => {
    selectedZonesVar(options);
  };
  return (
    <SelectBox
      options={zoneNames.sort()}
      handleSelect={createSetSelectedFn(setSelectedZones)}
      columnTitle={'Zones'}
      isMobile={true}
      selectedValues={selectedZones}
    />
  );
};

const Customers = ({
  customerNames,
}: {
  customerNames: string[];
}): JSX.Element => {
  const selectedCustomers = useReactiveVar(selectedCustomersVar);
  const setSelectedCustomers = (options: IOptions[]) => {
    selectedCustomersVar(options);
  };
  console.log(`[Customers] selectedCustomers : ${selectedCustomers.length}`);
  return (
    <SelectBox
      options={customerNames.sort()}
      handleSelect={createSetSelectedFn(setSelectedCustomers)}
      columnTitle={'Customers'}
      isMobile={true}
      selectedValues={selectedCustomers}
    />
  );
};

const Categories = ({ categories }: { categories: string[] }): JSX.Element => {
  const selectedCategories = useReactiveVar(selectedCategoriesVar);
  const setSelectedCategories = (options: IOptions[]) => {
    selectedCategoriesVar(options);
  };
  return (
    <SelectBox
      options={categories.sort()}
      handleSelect={createSetSelectedFn(setSelectedCategories)}
      columnTitle={'Filter Categories'}
      isMobile={true}
      selectedValues={selectedCategories}
    />
  );
};

const SoftwareVersions = ({
  softwareVersions,
}: {
  softwareVersions: string[];
}): JSX.Element => {
  const selectedSoftwareVersions = useReactiveVar(selectedSoftwareVersionsVar);
  const setSelectedSoftwareVersions = (options: IOptions[]) => {
    selectedSoftwareVersionsVar(options);
  };
  return (
    <SelectBox
      options={softwareVersions.sort((a, b) => {
        const aNumber = versionToNumber(a);
        const bNumber = versionToNumber(b);
        return bNumber - aNumber;
      })}
      handleSelect={createSetSelectedFn(setSelectedSoftwareVersions)}
      columnTitle={'Software Versions'}
      isMobile={true}
      selectedValues={selectedSoftwareVersions}
    />
  );
};

const HardwareVersions = ({
  hardwareVersions,
}: {
  hardwareVersions: string[];
}): JSX.Element => {
  const selectedHardwareVersions = useReactiveVar(selectedHardwareVersionsVar);
  const setSelectedHardwareVersions = (options: IOptions[]) => {
    selectedHardwareVersionsVar(options);
  };
  return (
    <SelectBox
      options={hardwareVersions.sort((a, b) => {
        const aNumber = versionToNumber(a);
        const bNumber = versionToNumber(b);
        return bNumber - aNumber;
      })}
      handleSelect={createSetSelectedFn(setSelectedHardwareVersions)}
      columnTitle={'Hardware Versions'}
      isMobile={true}
      selectedValues={selectedHardwareVersions}
    />
  );
};

export const FilterGroup = Object.assign(FiltersRoot, {
  Systems,
  Zones,
  Customers,
  Categories,
  SoftwareVersions,
  HardwareVersions,
});

const getFilterFn = (selectedValues: IOptions[]) => {
  return (value: string) => {
    if (selectedValues.length === 0) {
      return true;
    }
    return selectedValues.map(({ label }) => label).includes(value);
  };
};

export const useFilters = () => {
  return {
    systemFilter: getFilterFn(useReactiveVar(selectedSystemsVar)),
    zoneFilter: getFilterFn(useReactiveVar(selectedZonesVar)),
    customerFilter: getFilterFn(useReactiveVar(selectedCustomersVar)),
    categoryFilter: getFilterFn(useReactiveVar(selectedCategoriesVar)),
    softwareVersionFilter: getFilterFn(
      useReactiveVar(selectedSoftwareVersionsVar)
    ),
    hardwareVersionFilter: getFilterFn(
      useReactiveVar(selectedHardwareVersionsVar)
    ),
  };
};
