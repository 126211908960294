import NeatLeafIcon from 'assets/svg/logo-black.svg?react';
import { headerMessageVar } from 'cache';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { StyledHeader, TopHeading } from '../shared_layout/constants';

const DesktopHeader: FC = () => {
  const { isMobile } = useScreenSize();
  const headerMessage = headerMessageVar();
  return (
    <StyledHeader id="desktop_header" isMobile={isMobile}>
      <Link to={'/'}>
        <NeatleafIcon />
      </Link>

      <TopHeading color="gray" variant="h4" isMobile={isMobile}>
        {headerMessage}
      </TopHeading>
    </StyledHeader>
  );
};

export default DesktopHeader;

const NeatleafIcon = styled(NeatLeafIcon)`
  margin-right: 90px;
`;
